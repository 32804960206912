import React from "react";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import callApi from "../helpers/callApi";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import { useMutation, useQueryClient, useQuery } from "react-query";
import Select from "react-select";

const schema = yup.object().shape({
  name: yup.string().required(),
  ref: yup.string().required(),
  security_group: yup.object(),
});

function ProjectForm({
  method,
  URL,
  modal,
  initialValues,
  accounts,
  instance,
  queryKey,
  setForm,
}) {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (values) => callApi(URL, method, instance, accounts, values),
    {
      onSuccess: async (data) => {
        // Invalidate and refetch
        queryClient.invalidateQueries(queryKey);
        const res = await data.json();
        if (method === "POST") {
          callApi(
            `/api/projects/${res.id}/add_space_types/`,
            "POST",
            instance,
            accounts
          );
        }
        setForm(false);
      },
    }
  );

  const { data: groups } = useQuery("groups", () =>
    callApi(`/api/groups/`, "GET", instance, accounts)
  );

  return (
    <Formik
      validationSchema={schema}
      onSubmit={async (
        values,
        { setSubmitting, setErrors, setStatus, resetForm }
      ) => {
        if (method === "POST") {
          values = { ...values, owner: accounts[0].username };
        }
        values.security_group = values.security_group.value;
        try {
          mutation.mutate(values);
          resetForm({});
          setStatus({ success: true });
        } catch (error) {
          console.log(error);
          setStatus({ success: false });
          setSubmitting(false);
          setErrors({ submit: error.message });
        }
      }}
      enableReinitialize
      initialValues={initialValues}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        setFieldTouched,
        setFieldValue,
      }) => (
        <Form
          className="projectform"
          noValidate
          onSubmit={handleSubmit}
          id={method === "PATCH" ? "project-edit-form" : "project-form"}
        >
          <Card style={{ padding: "20px 18px 0px 18px", margin: "15px 0px" }}>
            <Form.Row>
              <Col md="4">
                <Form.Group md="4" controlId="validationFormik01">
                  <Form.Label>Project Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.name && errors.name}
                    className="mb-2 mr-sm-2"
                    autoComplete="off"
                  />
                  <Form.Control.Feedback type="invalid">
                    {touched.name && errors.name}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
              </Col>
              {method === "POST" && (
                <Col md="4">
                  <Form.Group md="4" controlId="validationFormik02">
                    <Form.Label>Job Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="ref"
                      value={values.ref}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.ref && errors.ref}
                      className="mb-2 mr-sm-2"
                      autoComplete="off"
                    />
                    <Form.Control.Feedback type="invalid">
                      {touched.ref && errors.ref}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              )}
              <Col md="2">
                {groups &&
                  accounts[0].idTokenClaims.roles.includes("Admin.Write") && (
                    <Form.Group md="4" controlId="validationFormik02">
                      <Form.Label>Security Group</Form.Label>
                      <Select
                        name="security_group"
                        options={groups.map((group) => ({
                          value: group.name,
                          label: group.name,
                        }))}
                        defaultValue={initialValues.security_group}
                        onChange={(selectedOption) =>
                          setFieldValue("security_group", selectedOption)
                        }
                        onBlur={() => setFieldTouched("security_group")}
                        className={
                          touched.security_group && errors.security_group
                            ? "error mb-2 mr-sm-2 selectbox"
                            : "mb-2 mr-sm-2 selectbox"
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {touched.security_group && errors.security_group}
                      </Form.Control.Feedback>
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                  )}
              </Col>
            </Form.Row>
            {!modal && (
              <Form.Group md="4">
                <Button type="submit" className="mb-2">
                  {method === "POST" ? "Create" : "Save"}
                </Button>
              </Form.Group>
            )}
          </Card>
        </Form>
      )}
    </Formik>
  );
}

export default ProjectForm;
