import React from "react";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import callApi from "../helpers/callApi";
import { useMutation, useQueryClient } from "react-query";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Select from "react-select";
import { demandCalcTypeSelect } from "../helpers/dropdownConstants";

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  efficiency: yup.number().required("Efficiency is required"),
  calc_type: yup.string().required("Calculation Type is required"),
});

function DistributionForm({
  method,
  URL,
  initialValues = {
    name: "",
    efficiency: 0,
  },
  accounts,
  instance,
  queryKey,
  setForm,
  parent,
}) {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (values) => callApi(URL, method, instance, accounts, values),
    {
      onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries(queryKey);
        setForm(false);
      },
    }
  );

  return (
    <Formik
      validationSchema={schema}
      onSubmit={async (
        values,
        { setSubmitting, setErrors, setStatus, resetForm }
      ) => {
        values = { ...values, project: parent };
        try {
          mutation.mutate(values);
          resetForm({});
          setStatus({ success: true });
        } catch (error) {
          console.log(error);
          setStatus({ success: false });
          setSubmitting(false);
          setErrors({ submit: error.message });
        }
      }}
      enableReinitialize
      initialValues={initialValues}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
        setFieldTouched,
        values,
        touched,
        isValid,
        errors,
        status,
      }) => (
        <Form
          className="projectform"
          onSubmit={handleSubmit}
          id={
            method === "PATCH" ? "distribution-edit-form" : "distribution-form"
          }
        >
          <Card style={{ padding: "10px 18px 3px 18px", margin: "15px 0px" }}>
            <Form.Row>
              <Col md="3">
                <Form.Group md="4" controlId="validationFormik01">
                  <Form.Label>Distribution Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    isInvalid={touched.name && errors.name}
                    onBlur={handleBlur}
                    className="mb-2 mr-sm-2"
                    autoComplete="off"
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {touched.name && errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md="3">
                <Form.Group md="4" controlId="validationFormik02">
                  <Form.Label>Efficiency</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      name="efficiency"
                      autoComplete="off"
                      style={{ width: "120px" }}
                      value={values.efficiency}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="mb-2 intfields"
                      isInvalid={touched.efficiency && errors.efficiency}
                    />
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    {touched.carbon_factor && errors.carbon_factor}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md="3">
                <Form.Group md="4" controlId="validationFormik04">
                  <Form.Label>Calculation Type</Form.Label>
                  <Select
                    name="calc_type"
                    options={demandCalcTypeSelect}
                    defaultValue={initialValues.calc_type}
                    onChange={(selectedOption) =>
                      setFieldValue("calc_type", selectedOption.value)
                    }
                    onBlur={() => setFieldTouched("calc_type")}
                    className={
                      touched.calc_type && errors.calc_type
                        ? "error mb-2 mr-sm-2 selectbox"
                        : "mb-2 mr-sm-2 selectbox"
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {touched.calc_type && errors.calc_type}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Form.Row>
            {method !== "PATCH" && (
              <Form.Group md="4">
                <Button type="submit" className="mb-2">
                  {method === "POST" ? "Create" : "Save"}
                </Button>
              </Form.Group>
            )}
          </Card>
        </Form>
      )}
    </Formik>
  );
}

export default DistributionForm;
