import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import { useState } from "react";
import { useMsal } from "@azure/msal-react";
import DeleteCheck from "./DeleteCheck";
import { useRouteMatch } from "react-router-dom";
import { demandCalcType } from "../helpers/dropdownConstants";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import EditButton from "./EditButton";
import DistibutionForm from "./DistributionForm";

const DistributionTable = ({ data, edit = false, global = true }) => {
  const { SearchBar } = Search;
  const { instance, accounts } = useMsal();
  const urlMatch = useRouteMatch();
  const projectId = urlMatch.params.projectId;
  const [editDemand, setEditDemand] = useState({ open: false });

  const columns = [
    {
      dataField: "name",
      text: "Distribution",
    },
    {
      dataField: "efficiency",
      text: "Efficiency",
    },
    {
      dataField: "calc_type",
      formatter: (cell, row) => demandCalcType[row.calc_type],
      text: "Calculation Type",
    },
    {
      dataField: "edit",
      text: "",
      formatter: (cell, row) => (
        <div style={{ marginLeft: "30px" }}>
          <EditButton
            disabled={
              global && !accounts[0].idTokenClaims.roles.includes("Admin.Write")
            }
            handleEdit={() => {
              setEditDemand({ open: true, existing: row });
            }}
          />
        </div>
      ),
      isDummyField: true,
    },
    {
      dataField: "deletefield",
      text: "",
      hidden: !edit,
      formatter: (cell, row) => (
        <DeleteCheck
          id={row.id}
          queryKey={global ? "global_demand" : ["demand", projectId]}
          type={global ? "global_demand" : "demand"}
          context={global ? "global distribution" : "distribution"}
        />
      ),
      isDummyField: true,
    },
  ];
  return (
    <div>
      <ToolkitProvider keyField="id" data={data} columns={columns} search>
        {(props) => (
          <div>
            <SearchBar {...props.searchProps} style={{ width: "300px" }} />
            <br />
            <BootstrapTable
              {...props.baseProps}
              bordered={false}
              hover
              noDataIndication="There are no templates available"
            />
          </div>
        )}
      </ToolkitProvider>
      <Modal
        centered
        show={editDemand.open}
        dialogClassName="modal-100w"
        onHide={() => setEditDemand({ ...editDemand, open: false })}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Source</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editDemand.existing && (
            <DistibutionForm
              method="PATCH"
              global={global}
              projectId={projectId}
              URL={
                global
                  ? `/api/global_demand/${editDemand.existing.id}/`
                  : `/api/demand/${editDemand.existing.id}/`
              }
              accounts={accounts}
              instance={instance}
              initialValues={{
                name: editDemand.existing.name,
                efficiency: editDemand.existing.efficiency,
                calc_type: editDemand.existing.calc_type,
              }}
              queryKey={global ? "global_demand" : ["demand", projectId]}
              setForm={(state) => setEditDemand({ open: state })}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setEditDemand({ ...editDemand, open: false })}
          >
            Cancel
          </Button>
          <Button variant="primary" type="submit" form="distribution-edit-form">
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DistributionTable;
