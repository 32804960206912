import { useMsal } from "@azure/msal-react";
import Button from "react-bootstrap/Button";
import { SiteGeo, SelectedFeature, SelectedBuilding, ShowModal } from "types";
import { MdBolt } from "react-icons/md";
import { useHistory } from "react-router-dom";

type Props = {
  selectedFeature: SelectedFeature;
  siteGeo: SiteGeo;
  setEditBuilding: (building: ShowModal<SelectedBuilding>) => void;
};

const ControlPanel = ({ selectedFeature, siteGeo, setEditBuilding }: Props) => {
  const { accounts } = useMsal();
  const history = useHistory();

  return (
    <div className="control-panel">
      <div>
        {selectedFeature.type === "building" && (
          <>
            <p>
              Name: {selectedFeature.building.name}
              <br />
              Completion Date: {selectedFeature.building.build_date}
              <br />
              Demolition Date: {selectedFeature.building.demo_date}
            </p>
            <p>
              Total Area: <b>{selectedFeature.building.area + "m²"}</b>
            </p>
            {selectedFeature.building.description ? (
              <p>Comments: {selectedFeature.building.description}</p>
            ) : (
              <p>
                <span style={{ color: "grey" }}>
                  There are no additional comments for this building...
                </span>
              </p>
            )}
            {accounts[0].idTokenClaims?.roles?.includes("Admin.Write") && (
              <Button
                onClick={() => {
                  setEditBuilding({
                    open: true,
                    existing: selectedFeature.building,
                  });
                }}
              >
                Edit Selected Building
              </Button>
            )}
          </>
        )}
        {selectedFeature.type === "electrical" && (
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              <h2 style={{ fontSize: "1.2em", fontWeight: "700" }}>
                {selectedFeature.electrical.name}
              </h2>
              <MdBolt
                style={{
                  marginLeft: "auto",
                  fontSize: "1.4em",
                  marginBottom: "7px",
                }}
              />
            </div>
            <p
              style={{
                marginTop: "5px",
                lineHeight: "30px",
                maxWidth: "200px",
              }}
            >
              <b>Parent:</b> {selectedFeature.electrical.parent?.name || "N/A"}
              <br />
              <b>Children: </b>
              {selectedFeature.electrical?.children?.map(
                (child, index) => (index !== 0 ? ", " : "") + child.name
              )}
              <br />
              <b>Buildings: </b>
              {selectedFeature.electrical?.buildings?.map(
                (building, index) => (index !== 0 ? ", " : "") + building.name
              )}
            </p>
            <Button onClick={() => history.push(`../electrical_network`)}>
              View Electrical Diagram
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default ControlPanel;
