import React from 'react'
import Plot from 'react-plotly.js';

const colors = {
  heating: "#e61005",
  cooling: "#0054f0",
  humidification: "#00ad11"
}

const Chart = ({data, type, label}) => {
  const layout = {
    autosize : true,
    hovermode: 'x unified',
    xaxis: {
      title: "Time",
    },
    yaxis: {
      title: {
        text: "Energy (KWhr)",
        standoff: 10
      }
      
    },
    showlegend: false
  }

  return (
    <div>
      {data.year_values[type] ? 
      <Plot
        data={[
          {
            x: data.year_values.date,
            y: data.year_values[type],
            text: data.year_values.outside_temp,
            customdata: data.year_values[type + "_carbon"],
            type: 'scatter',
            mode: 'lines',
            line: {
              color: colors[type],
              width: 1
            },
            hovertemplate: 'Energy: %{y} kWhr<br> Outside Temp: %{text}°C<br> Carbon: %{customdata} kgCO<sub>2</sub><br><extra></extra>'
          },
          {
            x: [data.year_values.date[0], data.year_values.date[data.year_values.date.length - 1]],
            y: [data.energy_maxs[type], data.energy_maxs[type]],
            mode: 'lines+markers+text',
            name: 'Lines, Markers and Text',
            text: ["", '<b>Max: ' + data.energy_maxs[type].toLocaleString() + " kWhr</b>"],
            textposition: "top left",
            type: 'line'
          },
          {
            x: [data.year_values.date[0], data.year_values.date[data.year_values.date.length - 1]],
            y: [data.energy_means[type], data.energy_means[type]],
            mode: 'lines+markers+text',
            name: 'Lines, Markers and Text',
            text: ["",'<b>Mean: ' + data.energy_means[type].toLocaleString() + " kWhr</b>"],
            textposition: "top left",
            type: 'line',
            line: {
              color: '#a200fa',
            }
          }
        ]}
        layout={ {...layout, title: label + " Energy"} }
        style={{width: "100%", height: "100%"}}
        useResizeHandler={true}
        config={{
          displayLogo: false
        }}
      /> :
      <b>
				<hr></hr>No {label} Source has been Provided <hr></hr>
			</b> 
    }
    </div>
  )
}

export default Chart