import React, { useState } from "react";
import { useQuery } from "react-query";
import { useMsal } from "@azure/msal-react";
import callApi from "../helpers/callApi";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import DistributionTable from "../components/DistributionTable";
import Button from "react-bootstrap/Button";
import DistributionForm from "../components/DistributionForm";
import Collapse from "react-bootstrap/Collapse";
import { useRouteMatch } from "react-router-dom";
import Breadcrumbs from "../components/Breadcrumbs";

const Distribution = () => {
  const { instance, accounts } = useMsal();

  const [distributionForm, setDistributionForm] = useState(false);

  const urlMatch = useRouteMatch();
  const projectId = urlMatch.params.projectId;

  const projectApiURL =
    process.env.REACT_APP_URL + `/api/projects/${urlMatch.params.projectId}/`;

  const { data, status, error } = useQuery(["demand", projectId], () =>
    callApi(`/api/demand?project=${projectId}`, "GET", instance, accounts)
  );

  if (status === "error") {
    return <span>Error: {error.message}</span>;
  }

  if (status === "loading") {
    return <span>Loading</span>;
  }
  return (
    <div>
      <Breadcrumbs urlMatch={urlMatch} />
      <Row>
        <Col>
          <h2 className="categoryheader">Project Distribution</h2>
        </Col>
        <Col md="auto">
          <Button
            className="create-new"
            variant="outline-primary"
            onClick={() => setDistributionForm(!distributionForm)}
          >
            {distributionForm ? "Cancel" : "New Distribution"}
          </Button>
        </Col>
      </Row>
      <Collapse in={distributionForm}>
        <div className="form">
          <DistributionForm
            parent={projectApiURL}
            projectId={projectId}
            method="POST"
            URL={`/api/demand/`}
            accounts={accounts}
            instance={instance}
            queryKey="demand"
            setForm={setDistributionForm}
            global={false}
          />
        </div>
      </Collapse>
      <Row>
        <DistributionTable data={data} edit={true} global={false} />
      </Row>
    </div>
  );
};

export default Distribution;
