import React, { useState, useRef, useEffect, useMemo } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { useRouteMatch } from "react-router-dom";
import ReactMap, { Source, Layer, Marker, LayerProps } from "react-map-gl";
import BuildingForm from "../forms/BuildingForm";
import Breadcrumbs from "../components/Breadcrumbs";
import ControlPanel from "../components/ControlPanel";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import mapFilterHandler from "../helpers/mapFilterHandler";
import filterMapBuildings from "../helpers/filterMapBuildings";
import mapSelectHandler from "../helpers/mapSelectHandler";
import setupMap from "../helpers/setupMap";
import { useElectricalBoards, useSiteGeo } from "queries";
import { electricalToGeo } from "helpers/electricalToGeo";
import { MdBolt } from "react-icons/md";
import { Filters, SelectedBuilding, SelectedFeature, ShowModal } from "types";
import { Map } from "mapbox-gl";
import mapColors from "assets/MapColors";

const SiteMap = () => {
  const urlMatch: any = useRouteMatch();
  const mapRef = useRef<Map>();
  const siteId = urlMatch.params.siteId;
  const [editBuilding, setEditBuilding] = useState<ShowModal<SelectedBuilding>>(
    {
      open: false,
    }
  );
  const [selectedFeature, setSelectedFeature] = useState<
    SelectedFeature | undefined
  >(undefined);
  const [cursor, setCursor] = useState<"auto" | "pointer">("auto");
  const [year, setYear] = useState<number>(2022);
  const [search, setSearch] = useState<string>("");
  const [filters, setFilters] = useState<Filters[]>(["building", "electrical"]);

  const { siteGeo, error, isLoading } = useSiteGeo(siteId);

  const { boards } = useElectricalBoards(siteId);

  const buildingLayerStyle: LayerProps = {
    id: "site-data",
    type: "fill",
    paint: {
      "fill-color": ["feature-state", "selected"],
    },
  };

  const electricalConnectionLayerStyle: LayerProps = {
    id: "electrical-connection-data",
    type: "line",
    paint: {
      "line-color": [
        "match",
        ["get", "connectionType"],
        "parent",
        "red",
        "child",
        "yellow",
        "building",
        "green",
        "black",
      ],
      "line-width": 2,
    },
  };
  const electricalMarkers = useMemo(
    () =>
      boards &&
      boards.map((board) => (
        <Marker
          key={board.id}
          latitude={board.coordinates.latitude}
          longitude={board.coordinates.longitude}
          anchor="bottom"
          onClick={() => {
            // set unselected color for building
            if (selectedFeature && selectedFeature.type === "building") {
              mapRef.current?.setFeatureState(
                { source: "site-data", id: selectedFeature.building.geo_id },
                {
                  selected: mapColors.hasBuilding,
                }
              );
            }
            setSelectedFeature({ type: "electrical", electrical: board });
          }}
          style={{ cursor: "pointer" }}
        >
          <MdBolt size={24} />
        </Marker>
      )),
    [boards, selectedFeature]
  );

  const filteredGeo = useMemo(
    () => siteGeo && filterMapBuildings(siteGeo.geojson, year, search),
    [siteGeo, year, search]
  );

  useEffect(() => {
    mapRef &&
      filters &&
      filteredGeo &&
      mapFilterHandler(mapRef, filteredGeo, filters);
  }, [filters, filteredGeo]);

  if (isLoading) {
    return <span>Loading</span>;
  }

  if (error) {
    return <span>Error, something went wrong, please try refreshing</span>;
  }

  console.log(selectedFeature);
  return (
    <div>
      <div className="input">
        <Breadcrumbs urlMatch={urlMatch} />
        {siteGeo && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "10px 0",
              flexWrap: "wrap",
              justifyContent: "space-evenly",
            }}
          >
            <h2 style={{ marginRight: "30px" }}>{siteGeo.name}</h2>
            <Form className="d-flex">
              <Form.Control
                type="text"
                placeholder="Search"
                value={search}
                aria-label="Search"
                style={{ marginRight: "30px", width: "300px" }}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Form>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "30px",
              }}
            >
              <input
                type="range"
                value={year}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setYear(parseInt(e.target.value))
                }
                min={1950}
                max={2050}
                style={{ width: "180px" }}
              />
              <label style={{ marginLeft: "15px", marginBottom: "4px" }}>
                Year: {year}
              </label>
            </div>
            <ToggleButtonGroup
              type="checkbox"
              value={filters}
              onChange={setFilters}
              style={{ margin: "10px 30px 10px 0px" }}
            >
              <ToggleButton
                id="tbg-btn-1"
                style={{
                  backgroundColor: filters.includes("building")
                    ? "#69be94"
                    : "#bfbfd5",
                  color: "white",
                }}
                value={"buildings"}
              >
                Buildings
              </ToggleButton>
              <ToggleButton
                id="tbg-btn-2"
                style={{
                  backgroundColor: filters.includes("electrical")
                    ? "#69be94"
                    : "#bfbfd5",
                  color: "white",
                }}
                value={"electrical"}
              >
                Electrical
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        )}
      </div>
      <div
        className="mainSection"
        style={{
          display: "flex",
          width: "calc(100vw - 20px)",
          marginLeft: "calc(-50vw + 50% + 10px)",
          flexWrap: "wrap",
        }}
      >
        {filteredGeo && (
          <>
            <ReactMap
              mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
              initialViewState={{
                latitude: 51.508,
                longitude: -0.1281,
                zoom: 10,
              }}
              style={{ width: "100%", height: "69vh" }}
              mapStyle="mapbox://styles/mapbox/streets-v9"
              onMouseEnter={() => setCursor("pointer")}
              onMouseLeave={() => setCursor("auto")}
              cursor={cursor}
              onClick={(event) =>
                siteGeo &&
                mapSelectHandler(
                  event,
                  mapRef,
                  siteGeo.geojson,
                  setSelectedFeature,
                  selectedFeature
                )
              }
              onLoad={() => setupMap(filteredGeo, mapRef, filters)}
              interactiveLayerIds={[buildingLayerStyle.id as string]}
              ref={(ref) => {
                const map = ref && ref.getMap();
                if (map) {
                  mapRef.current = map;
                }
              }}
            >
              <Source id="site-data" type="geojson" data={filteredGeo}>
                <Layer {...buildingLayerStyle} />
              </Source>
              {selectedFeature?.type === "electrical" && (
                <Source
                  id="electrical-connection-data"
                  type="geojson"
                  data={electricalToGeo(selectedFeature.electrical)}
                >
                  <Layer {...electricalConnectionLayerStyle} />
                </Source>
              )}
              {filters.includes("electrical") && electricalMarkers}
            </ReactMap>
            {selectedFeature && (
              <p style={{ width: "100%", marginLeft: "20px" }}>
                {selectedFeature.type === "building" &&
                  "GeoJSON ID:" + selectedFeature.building.geo_id}
              </p>
            )}
            {selectedFeature ? (
              siteGeo && (
                <ControlPanel
                  selectedFeature={selectedFeature}
                  siteGeo={siteGeo}
                  setEditBuilding={setEditBuilding}
                />
              )
            ) : (
              <p>
                <span style={{ color: "grey" }}>
                  {" "}
                  None selected, click on an item to view info
                </span>
              </p>
            )}
          </>
        )}
      </div>
      {filteredGeo && (
        <div style={{ marginBottom: "40px" }}>
          <p
            style={{
              color: "Black",
              marginTop: "15px",
              marginBottom: "0px",
              fontSize: "1em",
            }}
          >
            <b>Site Data for {year}</b>
          </p>
          <hr style={{ marginBottom: "15px", marginTop: "0px" }}></hr>
          <Row>
            <Col>
              Number of Buildings: <b>{siteGeo?.building_count}</b>
            </Col>
            <Col>
              Total Building Area: <b>{siteGeo?.total_area + "m²"}</b>
            </Col>
          </Row>
        </div>
      )}
      {editBuilding.existing && (
        <Modal
          show={editBuilding.open}
          centered
          dialogClassName="modal-60w"
          onHide={() => setEditBuilding({ ...editBuilding, open: false })}
        >
          <Modal.Header>
            <Modal.Title>{`You are currently editing ${editBuilding.existing.name}`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {siteGeo && (
              <BuildingForm
                modal={true}
                siteMap={true}
                method="update"
                queryKey={["site", siteId]}
                setClose={() => setEditBuilding({ open: false })}
                geoData={siteGeo.geojson}
                updateId={editBuilding.existing.id}
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setEditBuilding({ ...editBuilding, open: false })}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              type="submit"
              form="building-edit-form"
              onClick={() => setEditBuilding({ ...editBuilding, open: false })}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default SiteMap;
