import authToken from "../auth/authToken";
import cookie from "react-cookies";
// make a call to the api using specified url, http method and body + auth stuff

const csrfToken = cookie.load("csrftoken");
const callApi = async (
  url,
  method,
  instance,
  accounts,
  body,
  contentType = "application/json",
  toJson = false
) => {
  let bearer = await authToken(instance, accounts);
  let headers = new Headers();
  headers.append("X-CSRFToken", csrfToken);
  headers.append("Authorization", bearer);
  contentType !== "multipart/form-data" &&
    headers.append("Content-Type", contentType);

  let options = {};
  if (body === null) {
    options = {
      method: method,
      headers: headers,
    };
  } else {
    body = contentType === "multipart/form-data" ? body : JSON.stringify(body);
    options = {
      method: method,
      body: body,
      headers: headers,
    };
  }

  const res = await fetch(process.env.REACT_APP_URL + url, options);
  if (method === "GET" || toJson) {
    const json = await res.json();
    return json;
  } else {
    return res;
  }
};

export default callApi;
