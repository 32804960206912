import { Feature, FeatureCollection } from "geojson";
import type { ElectricalBoard } from "types";
import centerOfMass from "@turf/center-of-mass";

export const electricalToGeo = (board: ElectricalBoard): FeatureCollection => {
  const childConnections: Feature[] = board.children.map((childFeature) => ({
    type: "Feature",
    geometry: {
      type: "LineString",
      coordinates: [
        [board.coordinates.longitude, board.coordinates.latitude],
        [childFeature.coordinates.longitude, childFeature.coordinates.latitude],
      ],
    },
    properties: {
      name: childFeature.name,
      connectionType: "child",
    },
  }));
  let parentConnections: Feature | undefined = undefined;

  if (board.parent) {
    parentConnections = {
      type: "Feature",
      geometry: {
        type: "LineString",
        coordinates: [
          [board.coordinates.longitude, board.coordinates.latitude],
          [
            board.parent.coordinates.longitude,
            board.parent.coordinates.latitude,
          ],
        ],
      },
      properties: {
        name: board.parent.name,
        connectionType: "parent",
      },
    };
  }

  let buildingConnections: Feature[] | undefined = undefined;

  if (board.buildings.length > 0) {
    buildingConnections = board.buildings.map(
      (building) =>
        building.feature && {
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: [
              [board.coordinates.longitude, board.coordinates.latitude],
              centerOfMass(building.feature).geometry.coordinates,
            ],
          },
          properties: {
            name: building.name,
            connectionType: "building",
          },
        }
    );
  }
  const features: Feature[] = [...childConnections];
  if (parentConnections) {
    features.push(parentConnections);
  }
  if (buildingConnections) {
    features.push(...buildingConnections);
  }

  return {
    type: "FeatureCollection",
    features: features,
  };
};
