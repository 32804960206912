import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import { connectBuildingSchema } from "./schemas/connectBuildingsSchema";
import { Building, ConnectBuilding, ConnectBuildingFields } from "types";
import Card from "react-bootstrap/Card";
import Select from "react-select";
import { useBuildings } from "../queries";
import { useRouteMatch } from "react-router-dom";
import { useUpdate } from "mutations";

type Props = {
  boardId: number;
  existingBuildings: Building[];
  setClose: () => void;
};

const ConnectBuildingForm: React.FunctionComponent<Props> = ({
  boardId,
  existingBuildings,
  setClose,
}) => {
  const urlMatch: any = useRouteMatch();
  const siteId = urlMatch.params.siteId;
  const { buildings } = useBuildings(siteId);

  const editMutation = useUpdate<ConnectBuildingFields, ConnectBuilding>(
    "electrical_boards",
    "electrical_network",
    () => setClose()
  );

  let initialValues: ConnectBuilding = {
    buildings: existingBuildings,
  };

  return (
    <Formik
      validationSchema={connectBuildingSchema}
      onSubmit={async (values, { setSubmitting, setStatus, resetForm }) => {
        console.log(values);
        try {
          editMutation.mutate({
            data: {
              buildings: values.buildings.map((building) => building.id),
            },
            id: `${boardId}/connect_buildings`,
          });
          resetForm({});
          setStatus({ success: true });
        } catch (error) {
          console.log(error);
          setStatus({ success: false });
          setSubmitting(false);
        }
      }}
      enableReinitialize
      initialValues={initialValues}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        setFieldTouched,
        setFieldValue,
      }) => (
        <Form
          className="electrical-board-form"
          onSubmit={handleSubmit}
          id="connect-buildings-form"
        >
          <Card style={{ padding: "20px 18px 0px 18px", margin: "15px 0px" }}>
            <Form.Group controlId="validationFormik01">
              <Form.Label>Buildings</Form.Label>
              {buildings && (
                <Select<Building, true>
                  name="buildings"
                  options={buildings}
                  getOptionLabel={(building) => building.name}
                  getOptionValue={(building) => building.id.toString()}
                  defaultValue={initialValues.buildings || undefined}
                  onChange={(option) => {
                    setFieldValue("buildings", option);
                  }}
                  onBlur={() => setFieldTouched("buildings")}
                  className={
                    touched.buildings && errors.buildings
                      ? "error mb-2 mr-sm-2 selectbox"
                      : "mb-2 mr-sm-2 selectbox"
                  }
                  isMulti={true}
                />
              )}
              <Form.Control.Feedback type="invalid">
                {touched.buildings && !!errors.buildings}
              </Form.Control.Feedback>
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default ConnectBuildingForm;
