import { useRef, useState } from "react";
import ReactMap, { Source, Layer } from "react-map-gl";
import bbox from "@turf/bbox";

const layerStyle = {
	id: "Buildings",
	type: "fill",
	paint: {
		"fill-color": [
			"case",
			["boolean", ["feature-state", "selected"], false],
			"#E66100",
			"#5D3A9B",
		],
	},
};

const MapSelection = ({ geoData, setBuildingGeoId, buildingGeoId }) => {
	const [cursor, setCursor] = useState("auto");
	const mapRef = useRef();

	const onSelect = (event) => {
		buildingGeoId &&
			mapRef.current.setFeatureState(
				{ source: "site-data", id: buildingGeoId },
				{ selected: false }
			);
		const { features } = event;
		const selectedFeature = features && features[0];

		mapRef.current.setFeatureState(
			{ source: "site-data", id: selectedFeature.id },
			{ selected: true }
		);
		setBuildingGeoId(selectedFeature && selectedFeature.id);
	};

	const setupMap = () => {
		// Auto-zoom to the extent of the data
		const [minLng, minLat, maxLng, maxLat] = bbox(geoData);
		mapRef.current.fitBounds(
			[
				[minLng, minLat],
				[maxLng, maxLat],
			],
			{ padding: 40, duration: 0 }
		);
		// hide the buildings layer
		mapRef.current.setLayoutProperty("building", "visibility", "none");
		mapRef.current.setLayoutProperty("building-line", "visibility", "none");

		// set initial building selection
		buildingGeoId &&
			mapRef.current.setFeatureState(
				{ source: "site-data", id: buildingGeoId },
				{ selected: true }
			);
	};

	return (
		<div>
			<ReactMap
				mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
				initialViewState={{
					latitude: 51.508,
					longitude: -0.1281,
					zoom: 8,
				}}
				style={{ width: 330, height: 242 }}
				mapStyle="mapbox://styles/mapbox/streets-v9"
				onMouseEnter={() => setCursor("pointer")}
				onMouseLeave={() => setCursor("auto")}
				cursor={cursor}
				onClick={onSelect}
				onLoad={setupMap}
				interactiveLayerIds={[layerStyle.id]}
				ref={(ref) => {
					const map = ref && ref.getMap();
					if (map) {
						mapRef.current = map;
					}
				}}
			>
				<Source id="site-data" type="geojson" data={geoData}>
					<Layer {...layerStyle} />
				</Source>
			</ReactMap>
		</div>
	);
};

export default MapSelection;
