import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { LinkContainer } from "react-router-bootstrap";
const Breadcrumbs = ({ urlMatch }) => {
	const urlArray = urlMatch.url.split("/").filter((x) => x);
	const chunk = (array, size) =>
		Array.from({ length: Math.ceil(array.length / size) }, (value, index) =>
			array.slice(index * size, index * size + size)
		);
	const urlChunks = chunk(urlArray, 3);
	const getPath = (urlChunks, index) => {
		let temp = urlChunks;
		temp = temp.slice(0, index + 1);
		temp = [].concat.apply([], temp);
		temp = temp.join("/");
		temp = "/" + temp;
		return temp;
	};
	// make underscores spaces and capitalize first letter
	const formatName = (name) => {
		const temp = name.split("_");
		temp.forEach((x, i) => {
			temp[i] = x.charAt(0).toUpperCase() + x.slice(1);
		});
		return temp.join(" ");
	}
	return (
		<div>
			<Breadcrumb>
				<LinkContainer to="/projects">
					<Breadcrumb.Item>Projects</Breadcrumb.Item>
				</LinkContainer>
				{urlChunks.map((urlChunk, index) => {
					if(urlChunk.length === 1){
						return (
							<LinkContainer
								key={urlChunk}
								to={urlMatch.url}
							>
								<Breadcrumb.Item>{formatName(urlChunk[0])}</Breadcrumb.Item>
							</LinkContainer>
						)
					}
					return (
						<LinkContainer
							key={urlChunk[2] + urlChunk[1]}
							to={getPath(urlChunks, index)}
						>
							<Breadcrumb.Item>{urlChunk[2]}</Breadcrumb.Item>
						</LinkContainer>
					);
				})}
			</Breadcrumb>
		</div>
	);
};

export default Breadcrumbs;
