import React from "react";
import { MdDelete } from "react-icons/md";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import { useDelete } from "../mutations";

const DeleteCheck = ({
  id,
  type,
  queryKey,
  disabled,
  context,
  description = <> </>,
  size="md",
}) => {
  const [deleteAlert, setDeleteAlert] = useState({ open: false });
  const mutation = useDelete(type, queryKey, () =>
    setDeleteAlert({ open: false })
  );
  return (
    <div>
      <Button
        variant="outline-primary"
        disabled={disabled}
        onClick={() => setDeleteAlert({ open: true })}
        size={size}
      >
        <MdDelete />
      </Button>
      <Modal
        centered
        show={deleteAlert.open}
        dialogClassName="modal-100w"
        onHide={() => setDeleteAlert({ open: false })}
      >
        <Modal.Header closeButton>
          <Modal.Title>{`Are you sure you want to delete this ${context}?`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you'd like to delete this?
          {description && description}
          This action can not be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => setDeleteAlert({ open: false })}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            type="submit"
            onClick={() => {
              mutation.mutate(id);
              setDeleteAlert({ open: false });
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DeleteCheck;
