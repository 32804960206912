import React, { useState, useRef } from "react";
import { useRouteMatch, useHistory, useParams } from "react-router-dom";
import BuildingForm from "../forms/BuildingForm";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import SpaceForm from "../components/SpaceForm";
import EditButton from "../components/EditButton";
import Breadcrumbs from "../components/Breadcrumbs";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import Modal from "react-bootstrap/Modal";
import Collapse from "react-bootstrap/Collapse";
import { useMsal } from "@azure/msal-react";
import callApi from "../helpers/callApi";
import { useQuery, useMutation } from "react-query";
import { LinkContainer } from "react-router-bootstrap";
import DeleteCheck from "../components/DeleteCheck";
import ResultCharts from "../components/ResultCharts";
import Loader from "../components/Loader";

const Spaces = () => {
  const { instance, accounts } = useMsal();
  const history = useHistory();
  const { SearchBar } = Search;

  const params = useParams();
  const urlMatch = useRouteMatch();
  const buildingID = params.buildingId;
  const projectId = params.projectId;
  const siteId = params.siteId;
  const projectURL = `/project/${params.projectId}/${params.projectName}`;

  const [edit, setEdit] = useState(false);
  const [spaceForm, setSpaceForm] = useState(false);
  const [selected, setSelected] = useState([]);
  const [noSpace, setNoSpace] = useState(false);
  const [editSpace, setEditSpace] = useState({ open: false });

  const results = useRef(null);

  const handleSelect = (space_id) => {
    // Remove space_id from selection item
    var array = [...selected];
    var index = array.indexOf(space_id);
    if (index !== -1) {
      array.splice(index, 1);
      setSelected(array);
    }
    // Add space_id to selection
    else {
      setSelected(selected.concat(space_id));
    }
  };

  const {
    data: building,
    isLoading,
    error,
  } = useQuery(["building", buildingID], () =>
    callApi(`/api/buildings/${buildingID}/`, "GET", instance, accounts)
  );

  const calculateMutation = useMutation(
    () =>
      callApi(
        `/api/calculate/`,
        "POST",
        instance,
        accounts,
        { space_ids: selected },
        "application/json",
        true
      ),
    {
      mutationKey: "calculate",
      onSuccess: () => {
        results.current.scrollIntoView({ behavior: "smooth" });
      },
    }
  );

  const { data: site } = useQuery(
    ["site", siteId],
    () => callApi(`/api/sites/${siteId}`, "GET", instance, accounts),
    {
      enabled: !!siteId,
    }
  );

  const columns = [
    {
      dataField: "ref",
      text: "Ref",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "60px", textAlign: "center" };
      },
      footer: "",
    },
    {
      dataField: "space_type",
      text: "Space Type",
      formatter: (cell, row) => row.space_type.name,
      footer: "",
    },
    {
      dataField: "area",
      text: "Area (m²)",
      footer: (columnData) =>
        columnData.reduce((acc, item) => acc + item, 0) + " m²",
    },
    {
      dataField: "height",
      text: "Height (m)",
      footer: "",
    },
    {
      dataField: "heat_source",
      text: "Heating Source",
      formatter: (cell, row) => row.heat_source?.name,
      footer: "",
    },
    {
      dataField: "cool_source",
      text: "Cooling Source",
      formatter: (cell, row) => row.cool_source?.name,
      footer: "",
    },
    {
      dataField: "humid_source",
      text: "Humidification Source",
      formatter: (cell, row) => row.humid_source?.name,
      footer: "",
    },
    {
      dataField: "edit",
      text: "",
      formatter: (cell, row) => (
        <div style={{ marginLeft: "30px" }}>
          <EditButton
            handleEdit={() => {
              setEditSpace({ open: true, existing: row });
            }}
          />
        </div>
      ),
      isDummyField: true,
      footer: "",
    },
    {
      dataField: "delete",
      text: "",
      formatter: (cell, row) => (
        <DeleteCheck
          id={row.id}
          queryKey={["spaces", buildingID]}
          type="spaces"
          context="space"
        />
      ),
      isDummyField: true,
      footer: "",
    },
  ];

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    hideSelectAll: true,
    onSelect: (row) => {
      handleSelect(row.id);
    },
  };

  const defaultSorted = [
    {
      dataField: "ref",
      order: "asc",
    },
  ];

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <span>Error: {error.message}</span>;
  }

  console.log(buildingID)

  return (
    <div className="spacetable">
      <Breadcrumbs urlMatch={urlMatch} />
      <Row>
        <Col md="auto">
          {edit ? (
            <h2 className="categoryheadergreyed">Building: {building.name}</h2>
          ) : (
            <h2 className="categoryheader">Building: {building.name}</h2>
          )}
        </Col>
        <Col>
          <EditButton handleEdit={() => setEdit(!edit)} />
        </Col>
      </Row>
      <p>
        {building.ref} | {building.build_date} - {building.demo_date}
      </p>
      {site && (
        <Collapse in={edit}>
          <div>
            <BuildingForm
              method="update"
              queryKey={["building", buildingID]}
              updateId={parseInt(buildingID)}
              setClose={setEdit}
              geoData={site.geojson}
            />
          </div>
        </Collapse>
      )}

      <Row>
        <Col>
          <h3>Space List</h3>
        </Col>
        <Col md="auto">
          <LinkContainer to={`${projectURL}/space_types`}>
            <Button
              variant="outline-primary"
              onClick={() => history.push(projectURL)}
            >
              Space Types
            </Button>
          </LinkContainer>
        </Col>
        <Col md="auto">
          <Button
            className="create-new"
            variant="outline-primary"
            onClick={() => setSpaceForm(!spaceForm)}
          >
            {spaceForm ? "Cancel" : "Add Space"}
          </Button>
        </Col>
      </Row>
      <Collapse in={spaceForm}>
        <div className="form">
          <SpaceForm
            method="POST"
            URL="/api/spaces/"
            accounts={accounts}
            instance={instance}
            queryKey={["building", buildingID]}
            setForm={setSpaceForm}
            projectId={projectId}
            parent={building.url}
          />
        </div>
      </Collapse>
      {building?.spaces && (
        <ToolkitProvider
          keyField="id"
          data={building.spaces}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <SearchBar {...props.searchProps} style={{ width: "300px" }} />
              <br />
              <BootstrapTable
                {...props.baseProps}
                bordered={false}
                hover
                selectRow={selectRow}
                defaultSorted={defaultSorted}
                noDataIndication="No spaces have been created yet, click 'New Space' to make one"
              />
            </div>
          )}
        </ToolkitProvider>
      )}
      <Row style={{ marginBottom: "40px", marginTop: "30px" }}>
        <Col md="auto">
          <Button
            onClick={() => {
              if (selected.length === 0) {
                setNoSpace(true);
              } else {
                calculateMutation.mutate();
              }
            }}
          >
            {calculateMutation.isSuccess ? "Recalculate" : "Calculate"}
          </Button>
        </Col>
      </Row>
      <div ref={results} style={{ scrollMarginTop: "500px" }}>
        <ResultCharts calculateMutation={calculateMutation} />
      </div>
      <Modal centered show={noSpace} onHide={() => setNoSpace(false)}>
        <Modal.Header closeButton>
          <Modal.Title>No spaces selected</Modal.Title>
        </Modal.Header>
        <Modal.Body>Please select a space to perform a calculation.</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setNoSpace(false)}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        centered
        show={editSpace.open}
        dialogClassName="modal-100w"
        onHide={() => setEditSpace({ ...editSpace, open: false })}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Space</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editSpace.existing && (
            <SpaceForm
              method="PATCH"
              URL={`/api/spaces/${editSpace.existing.id}/`}
              accounts={accounts}
              instance={instance}
              initialValues={{
                ...editSpace.existing,
                space_type: {
                  label: editSpace.existing.space_type.name,
                  value: editSpace.existing.space_type.url,
                },
                heat_demand: {
                  label: editSpace.existing.heat_demand.name,
                  value: editSpace.existing.heat_demand.url,
                },
                cool_demand: {
                  label: editSpace.existing.cool_demand.name,
                  value: editSpace.existing.cool_demand.url,
                },
                humid_demand: {
                  label: editSpace.existing.humid_demand.name,
                  value: editSpace.existing.humid_demand.url,
                },
                heat_source: {
                  label: editSpace.existing.heat_source.name,
                  value: editSpace.existing.heat_source.url,
                },
                cool_source: {
                  label: editSpace.existing.cool_source.name,
                  value: editSpace.existing.cool_source.url,
                },
                humid_source: {
                  label: editSpace.existing.humid_source.name,
                  value: editSpace.existing.humid_source.url,
                },
              }}
              queryKey={["building", buildingID]}
              setForm={(state) => setEditSpace({ open: state })}
              projectId={projectId}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setEditSpace({ ...editSpace, open: false })}
          >
            Cancel
          </Button>
          <Button variant="primary" type="submit" form="space-edit-form">
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Spaces;
