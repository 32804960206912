import checkGeoIdForBuilding from "./checkGeoIdForBuilding";
import mapColors from "../assets/MapColors";
import { MapLayerMouseEvent, Map } from "mapbox-gl";
import { FeatureCollection } from "geojson";
import { SelectedBuilding, SelectedFeature } from "types";

const mapSelectHandler = (
  event: MapLayerMouseEvent,
  mapRef: React.MutableRefObject<Map | undefined>,
  siteGeoJson: FeatureCollection,
  setSelectedFeature: (feature: SelectedFeature) => void,
  selectedFeature?: SelectedFeature
) => {
  console.log(event);
  const { features } = event;
  if (event.features?.length && event.features?.length < 1) {
    return;
  }
  // set unselected color for building
  if (selectedFeature && selectedFeature.type === "building") {
    mapRef.current?.setFeatureState(
      { source: "site-data", id: selectedFeature.building.geo_id },
      {
        selected: mapColors.hasBuilding,
      }
    );
  }
  const selectedPolygon = features && features[0];

  if (selectedPolygon?.properties?.id) {
    mapRef.current?.setFeatureState(
      { source: "site-data", id: selectedPolygon?.id },
      { selected: mapColors.selected }
    );
    setSelectedFeature({
      building: selectedPolygon.properties as SelectedBuilding,
      type: "building",
    });
  }
};

export default mapSelectHandler;
