import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import { useMsal } from "@azure/msal-react";
import { useState } from "react";
import DeleteCheck from "./DeleteCheck";
import { useRouteMatch } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import EditButton from "../components/EditButton";
import SpaceTypeForm from "../components/SpaceTypeForm";

const SpaceTypeTable = ({ data, edit = false, global = true }) => {
  const { SearchBar } = Search;
  const { instance, accounts } = useMsal();
  const urlMatch = useRouteMatch();
  const projectId = urlMatch.params.projectId;
  const [editSpaceType, setEditSpaceType] = useState({ open: false });

  const columns = [
    {
      dataField: "name",
      text: "Space Type",
    },
    {
      dataField: "heat_source",
      formatter: (cell, row) => row.heat_source && row.heat_source.name,
      text: "Heating Source",
    },
    {
      dataField: "cool_source",
      text: "Cooling Source",
      formatter: (cell, row) => row.cool_source && row.cool_source.name,
    },
    {
      dataField: "humid_source",
      text: "Humidification Source",
      formatter: (cell, row) => row.humid_source && row.humid_source.name,
    },
    {
      dataField: "edit",
      text: "",
      formatter: (cell, row) => (
        <div style={{ marginLeft: "30px" }}>
          <EditButton
            disabled={
              global && !accounts[0].idTokenClaims.roles.includes("Admin.Write")
            }
            handleEdit={() => {
              setEditSpaceType({ open: true, existing: row });
            }}
          />
        </div>
      ),
      isDummyField: true,
    },
    {
      dataField: "deletefield",
      text: "",
      hidden: !edit,
      formatter: (cell, row) => (
        <DeleteCheck
          id={row.id}
          queryKey={global ? "global_space_types" : ["space_types", projectId]}
          type={global ? "global_space_types" : "space_types"}
          context={global ? "global space type" : "space type"}
        />
      ),
      isDummyField: true,
    },
  ];
  return (
    <div>
      <ToolkitProvider keyField="id" data={data} columns={columns} search>
        {(props) => (
          <div>
            <SearchBar {...props.searchProps} style={{ width: "300px" }} />
            <br />
            <div className="div-large">
              <BootstrapTable
                id="space_types_table"
                {...props.baseProps}
                bordered={false}
                hover
                noDataIndication="There are no templates available"
              />
            </div>
          </div>
        )}
      </ToolkitProvider>
      <Modal
        centered
        show={editSpaceType.open}
        dialogClassName="modal-100w"
        onHide={() => setEditSpaceType({ ...editSpaceType, open: false })}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Space Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editSpaceType.existing && (
            <SpaceTypeForm
              method="PATCH"
              global={global}
              projectId={projectId}
              URL={
                global
                  ? `/api/global_space_types/${editSpaceType.existing.id}/`
                  : `/api/space_types/${editSpaceType.existing.id}/`
              }
              accounts={accounts}
              instance={instance}
              initialValues={{
                name: editSpaceType.existing.name,
                heat_source: editSpaceType.existing.heat_source,
                cool_source: editSpaceType.existing.cool_source,
                humid_source: editSpaceType.existing.humid_source,
                heat_demand: editSpaceType.existing.heat_demand,
                cool_demand: editSpaceType.existing.cool_demand,
                humid_demand: editSpaceType.existing.humid_demand,
                room_air_temp: editSpaceType.existing.room_air_temp,
                room_air_rh: editSpaceType.existing.room_air_rh,
                ACperhr: editSpaceType.existing.ACperhr,
                percent_fresh_air: editSpaceType.existing.percent_fresh_air,
                ventilation_safety_factor:
                  editSpaceType.existing.ventilation_safety_factor,
                fabric_loss: editSpaceType.existing.fabric_loss,
                fabric_gain: editSpaceType.existing.fabric_gain,
                hws_load: editSpaceType.existing.hws_load,
                equipment_gain: editSpaceType.existing.equipment_gain,
                lighting_load: editSpaceType.existing.lighting_load,
                small_power_load: editSpaceType.existing.small_power_load,
                equipment_load: editSpaceType.existing.equipment_load,
                days: editSpaceType.existing.days,
                start_time: editSpaceType.existing.start_time,
                end_time: editSpaceType.existing.end_time,
                comments: editSpaceType.existing.comments,
              }}
              queryKey={
                global ? "global_space_types" : ["space_types", projectId]
              }
              setForm={(state) => setEditSpaceType({ open: state })}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setEditSpaceType({ ...editSpaceType, open: false })}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            form={global ? "global-space-edit-form" : "space-edit-form"}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SpaceTypeTable;
