import { useQuery } from "react-query";
import { useMsal } from "@azure/msal-react";
import React, { useState } from "react";
import callApi from "../helpers/callApi";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import DistributionTable from "../components/DistributionTable";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import DistributionForm from "../components/DistributionForm";

const GlobalDistribution = () => {
  const { instance, accounts } = useMsal();

  const [distributionForm, setDistributionForm] = useState(false);

  const { data, status, error } = useQuery("global_demand", () =>
    callApi(`/api/global_demand/`, "GET", instance, accounts)
  );

  if (status === "error") {
    return <span>Error: {error.message}</span>;
  }

  if (status === "loading") {
    return <span>Loading</span>;
  }
  return (
    <div>
      <Row>
        <Col>
          <h2 className="categoryheader">Global Distribution</h2>
        </Col>
        <Col md="auto">
          {accounts[0].idTokenClaims.roles.includes("Admin.Write") ? (
            <Button
              className="create-new"
              variant="outline-primary"
              onClick={() => setDistributionForm(!distributionForm)}
              disabled={
                !accounts[0].idTokenClaims.roles.includes("Admin.Write")
              }
            >
              {distributionForm ? "Cancel" : "New Global Distribution"}
            </Button>
          ) : (
            <></>
          )}
        </Col>
      </Row>
      <Collapse in={distributionForm}>
        <div className="form">
          <DistributionForm
            method="POST"
            URL="/api/global_demand/"
            accounts={accounts}
            instance={instance}
            queryKey="global_demand"
            setForm={setDistributionForm}
          />
        </div>
      </Collapse>
      <Row>
        <DistributionTable
          data={data}
          edit={
            global && accounts[0].idTokenClaims.roles.includes("Admin.Write")
          }
        />
      </Row>
    </div>
  );
};

export default GlobalDistribution;
