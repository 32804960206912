import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
type Props = {
  diagram: "sankey" | "network";
  setDiagram: (diagram: "sankey" | "network") => void;
};

const NetworkControl: React.FunctionComponent<Props> = ({
  diagram,
  setDiagram,
}) => {
  const history = useHistory();
  const toggle = () => {
    if (diagram === "sankey") {
      setDiagram("network");
    }
    if (diagram === "network") {
      setDiagram("sankey");
    }
  };

  return (
    <div>
      <div
        style={{
          marginLeft: "2em",
        }}
      >
        <ToggleButtonGroup
          type="checkbox"
          value={[diagram]}
          onChange={toggle}
          style={{ margin: "10px 30px 10px 10px" }}
        >
          <ToggleButton id="tbg-btn-1" value={"network"}>
            Network
          </ToggleButton>
          <ToggleButton id="tbg-btn-2" value={"sankey"}>
            Sankey
          </ToggleButton>
        </ToggleButtonGroup>
        <Button
          variant="outline-primary"
          onClick={() => history.push("../site_map")}
        >
          Site Map
        </Button>
      </div>
    </div>
  );
};

export default NetworkControl;
