import React, { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import BuildingForm from "../forms/BuildingForm";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import InfrastructureForm from "../components/InfrastructureForm";
import EditButton from "../components/EditButton";
import Breadcrumbs from "../components/Breadcrumbs";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import Modal from "react-bootstrap/Modal";
import Collapse from "react-bootstrap/Collapse";
import { useMsal } from "@azure/msal-react";
import callApi from "../helpers/callApi";
import { useQuery } from "react-query";
import findBuildingsServed from "../helpers/findBuildingsServed";
import moment from "moment";

const Infrastructure = () => {
  const { instance, accounts } = useMsal();
  const { SearchBar } = Search;

  const urlMatch = useRouteMatch();
  const infrastructureId = urlMatch.params.infrastructureId;
  const siteId = urlMatch.params.siteId;

  const [edit, setEdit] = useState(false);
  const [editBuilding, setEditBuilding] = useState({ open: false });

  const {
    data: infrastructure,
    status: infrastructureStatus,
    error,
  } = useQuery(["infrastructure", infrastructureId], () =>
    callApi(
      `/api/external_loads/${infrastructureId}/`,
      "GET",
      instance,
      accounts
    )
  );

  const { data: site, status: siteStatus } = useQuery(
    ["site", siteId],
    () => callApi(`/api/sites/${siteId}`, "GET", instance, accounts),
    {
      enabled: !!siteId,
    }
  );
  const columns = [
    {
      dataField: "ref",
      text: "Ref",
      sort: true,
      sortFunc: (a, b) => {
        let hasNumber = /\d/;
        if (hasNumber.test(a) || hasNumber.test(b)) {
          return a.match(/\d+/g) - b.match(/\d+/g);
        } else {
          return a - b;
        }
      },
      footer: "",
    },
    {
      dataField: "name",
      text: "Name",
      footer: "",
    },
    {
      dataField: "area",
      text: "GIFA (m²)",
      formatter: (cell, row) => {
        let gifa = 0;
        if (row.spaces.length > 0) {
          row.spaces.forEach((space) => {
            gifa += space.area;
          });
        }
        return gifa;
      },
      isDummyField: true,
      footer: "",
    },
    {
      dataField: "spaces",
      text: "Load (kW)",
      formatter: (cell, row) => {
        let load = 0;
        site.buildings.forEach((building) => {
          if (row.url === building.url) {
            if (row.spaces) {
              row.spaces.forEach(
                (space) =>
                  (load +=
                    ((space.lighting_load +
                      space.small_power_load +
                      space.equipment_load +
                      space.mechanical_load) *
                      space.area) /
                    1000)
              );
            }
          }
        });
        return Math.round(load);
      },
      isDummyField: true,
      footer: (columnData) => {
        let totalLoad = 0;
        site.buildings.forEach((building) => {
          columnData.forEach((spaces) => {
            if (spaces[0] && spaces[0].building === building.url) {
              spaces.forEach((space) => {
                totalLoad +=
                  ((space.lighting_load +
                    space.small_power_load +
                    space.equipment_load +
                    space.mechanical_load) *
                    space.area) /
                  1000;
              });
            }
          });
        });
        return Math.round(totalLoad) + " kW";
      },
    },
    {
      dataField: "build_date",
      text: "Build Date",
      footer: "",
    },
    {
      dataField: "demo_date",
      text: "Demolition Date",
      footer: "",
    },
    {
      dataField: "edit",
      text: "",
      formatter: (cell, row) => (
        <div style={{ marginLeft: "30px" }}>
          <EditButton
            handleEdit={() => {
              setEditBuilding({ open: true, existing: row });
            }}
            size=""
            disabled={accounts[0].idTokenClaims.roles.includes("Guest.Read")}
          />
        </div>
      ),
      isDummyField: true,
      footer: "",
    },
  ];

  const defaultSorted = [
    {
      dataField: "ref",
      order: "asc",
    },
  ];

  if (siteStatus === "loading" || infrastructureStatus === "loading") {
    return <span>Loading</span>;
  }

  if (siteStatus === "error" || infrastructureStatus === "error") {
    return <span>Error: {error.message}</span>;
  }

  return (
    <div className="spacetable">
      <Breadcrumbs urlMatch={urlMatch} />
      <Row>
        <Col md="auto">
          {edit ? (
            <h2 className="categoryheadergreyed">
              Infrastructure: {infrastructure.name}
            </h2>
          ) : (
            <h2 className="categoryheader">
              Infrastructure: {infrastructure.name}
            </h2>
          )}
        </Col>
        <Col>
          <EditButton handleEdit={() => setEdit(!edit)} />
        </Col>
      </Row>
      <p>
        {infrastructure.ref} | {infrastructure.service_type} -{" "}
        {infrastructure.load} kVA capacity
      </p>
      {infrastructure && (
        <Collapse in={edit}>
          <div>
            <InfrastructureForm
              method="PATCH"
              URL={`/api/external_loads/${infrastructureId}/`}
              queryKey={["infrastructure", infrastructureId]}
              initialValues={{
                ref: infrastructure.ref,
                name: infrastructure.name,
                service_type: infrastructure.service_type,
                buildings: infrastructure.buildings,
                load: infrastructure.load,
                coordinates: infrastructure.coordinates,
              }}
              setForm={setEdit}
              instance={instance}
              accounts={accounts}
              geoData={site.geojson}
              siteId={siteId}
            />
          </div>
        </Collapse>
      )}

      <Row>
        <Col>
          <h3>Buildings Served</h3>
        </Col>
      </Row>
      {site.buildings && (
        <ToolkitProvider
          keyField="id"
          data={findBuildingsServed(site, infrastructure.name, false)}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <SearchBar {...props.searchProps} style={{ width: "300px" }} />
              <br />
              <BootstrapTable
                {...props.baseProps}
                bordered={false}
                hover
                defaultSorted={defaultSorted}
                noDataIndication="This piece of infrastructure is not serving any buildings currently"
              />
            </div>
          )}
        </ToolkitProvider>
      )}
      <Modal
        centered
        show={editBuilding.open}
        dialogClassName="modal-100w"
        onHide={() => setEditBuilding({ ...editBuilding, open: false })}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Building</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editBuilding.existing && (
            <BuildingForm
              modal={true}
              method="PATCH"
              URL={`/api/buildings/${editBuilding.existing.id}/`}
              accounts={accounts}
              instance={instance}
              initialValues={{
                ref: editBuilding.existing.ref,
                name: editBuilding.existing.name,
                build_date: moment(editBuilding.existing.build_date),
                demo_date: moment(editBuilding.existing.demo_date),
                roof_area: editBuilding.existing.roof_area,
                description: editBuilding.existing.description,
                geo_id: editBuilding.existing.geo_id,
                external_loads: editBuilding.existing.external_loads,
              }}
              queryKey={["site", siteId]}
              setForm={(state) => setEditBuilding({ open: state })}
              geoData={site.geojson}
              siteId={siteId}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setEditBuilding({ ...editBuilding, open: false })}
          >
            Cancel
          </Button>
          <Button variant="primary" type="submit" form="building-edit-form">
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Infrastructure;
