const findBuildingsServed = (siteData, loadName, display = false) => {
  let objArray = [];
  let displayText = "";
  let firstWord = true;
  siteData.buildings.forEach((building) => {
    building.external_loads.forEach((load) => {
      if (load.name === loadName) {
        if (firstWord) {
          displayText = displayText.concat(building.name);
          firstWord = false;
        } else {
          displayText = displayText.concat(", ", building.name);
        }
        objArray.push(building);
      }
    });
  });
  if (display) {
    return displayText;
  } else {
    return objArray;
  }
};

export default findBuildingsServed;
