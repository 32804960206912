import React from "react";
import { MdEdit } from "react-icons/md";
import Button from "react-bootstrap/Button";

const EditButton = ({ handleEdit, size = "sm", disabled }) => {
	return (
		<div>
			<Button
				variant="outline-primary"
				size={size}
				onClick={handleEdit}
				disabled={disabled}
			>
				<MdEdit />
			</Button>
		</div>
	);
};

export default EditButton;
