import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import { useState } from "react";
import { useMsal } from "@azure/msal-react";
import DeleteCheck from "./DeleteCheck";
import { useRouteMatch } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import EditButton from "./EditButton";
import FuelForm from "./FuelForm";

const FuelTable = ({ data, edit = false, global = true }) => {
  const { SearchBar } = Search;
  const { instance, accounts } = useMsal();
  const urlMatch = useRouteMatch();
  const projectId = urlMatch.params.projectId;
  const [editFuel, setEditFuel] = useState({ open: false });

  const columns = [
    {
      dataField: "name",
      text: "Name",
    },
    {
      dataField: "carbon_factor",
      text: "Carbon Factor",
    },
    {
      dataField: "calorific_value",
      text: "Calorific Value",
    },
    // {
    // 	dataField: "price_pu",
    // 	text: "Price Per Unit",
    // },
    {
      dataField: "edit",
      text: "",
      formatter: (cell, row) => (
        <div style={{ marginLeft: "30px" }}>
          <EditButton
            disabled={
              global && !accounts[0].idTokenClaims.roles.includes("Admin.Write")
            }
            handleEdit={() => {
              setEditFuel({ open: true, existing: row });
            }}
          />
        </div>
      ),
      isDummyField: true,
    },
    {
      dataField: "deletefield",
      text: "",
      hidden: !edit,
      formatter: (cell, row) => (
        <DeleteCheck
          id={row.id}
          queryKey={global ? "global_fuel_types" : ["fuel_types", projectId]}
          type={global ? "global_fuel_types" : "fuel_types"}
          context={global ? "global fuel type" : "fuel type"}
        />
      ),
      isDummyField: true,
    },
  ];
  return (
    <ToolkitProvider keyField="id" data={data} columns={columns} search>
      {(props) => (
        <div>
          <SearchBar {...props.searchProps} style={{ width: "300px" }} />
          <br />
          <BootstrapTable
            {...props.baseProps}
            bordered={false}
            hover
            noDataIndication="There are no templates available"
          />
          <Modal
            centered
            show={editFuel.open}
            dialogClassName="modal-100w"
            onHide={() => setEditFuel({ ...editFuel, open: false })}
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit Fuel Type</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {editFuel.existing && (
                <FuelForm
                  method="PATCH"
                  URL={
                    global
                      ? `/api/global_fuel_types/${editFuel.existing.id}/`
                      : `/api/fuel_types/${editFuel.existing.id}/`
                  }
                  accounts={accounts}
                  instance={instance}
                  initialValues={{
                    name: editFuel.existing.name,
                    carbon_factor: editFuel.existing.carbon_factor,
                    calorific_value: editFuel.existing.calorific_value,
                  }}
                  queryKey={
                    global ? "global_fuel_types" : ["fuel_types", projectId]
                  }
                  setForm={(state) => setEditFuel({ open: state })}
                />
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setEditFuel({ ...editFuel, open: false })}
              >
                Cancel
              </Button>
              <Button variant="primary" type="submit" form="fuel-edit-form">
                Save
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </ToolkitProvider>
  );
};

export default FuelTable;
