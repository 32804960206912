import * as React from "react";

function Arup(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 261.9 140.2"
			width="6em"
			height="3em"
			{...props}
		>
			<path fill="none" d="M0 0h261.9v140.2H0z" />
			<path
				className="prefix__st2"
				fill="white"
				d="M173.8 77.5c0 9.5-7.9 17.1-17.3 17.1-9.5 0-17.1-7.7-17.1-17.1V52.3c0-4.1-3.6-4.1-3.6-4.1v-1.4h14.6v1.4s-3.6 0-3.6 4v26.9c0 5.7 4.6 10.3 10.3 10.3 5.7 0 10.2-4.6 10.2-10.3V52.4c0-4.1-3.7-4.1-3.7-4.1v-1.4h13.7v1.4s-3.6 0-3.6 4.1v25.1zm-68.5-25.8v35.4c0 5 4.1 4.9 4.1 4.9v1.5H94.6V92s3.3.1 3.3-4.6V52.3c0-4.1-4-4.1-4-4.1v-1.4h13.7c10.4 0 15.2 4.4 16.9 9 1.7 4.4.8 9.9-2.7 13.5-1.8 1.8-4.1 2.7-4.1 2.7s1.1.5 3.2 3.2c0 0 6.8 8.9 10.2 12.9 2.1 2.5 4.5 3.9 7.7 3.8l.2 1.1c-3.6 2-7.8 2.1-11.3-.2-6.6-4.3-12.1-14.8-14.8-17.5-2.9-2.9-4.9-3-4.9-3V71c.9.1 5.8.1 8.5-2.5s4.3-9.1-.4-14.2c-4.1-4.3-10.1-3.2-10.8-2.6zm-45 22.2h11.5l-6.4-15-5.1 15zm5.6-30.7l20.7 46.1c1.5 2.8 3.9 2.8 3.9 2.8v1.5H75.1v-1.5s4.5.1 2.5-4.5l-4.2-9.9H59l-3.5 10.1C53.9 92.1 58 92 58 92v1.5H45V92s2.9 0 4.5-4.5l16.4-44.3zM195 51.7v35.6c0 4.8 3.9 4.7 3.9 4.7v1.5h-14.8V92s3.4.1 3.4-4.5l-.1-35.2c0-4.1-3.6-4.1-3.6-4.1v-1.4h13.5c10.4 0 15.3 4.3 17 9 2.4 6.9-.7 11.9-3.4 14.4-6.6 6.1-13.4 1-13.4 1l.3-1c.9.1 5.8.9 8.5-1.7s4.3-9.1-.4-14.2c-4.2-4.3-10.2-3.2-10.9-2.6z"
			/>
		</svg>
	);
}

export default Arup;
