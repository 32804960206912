import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import { useState } from "react";
import { useMsal } from "@azure/msal-react";
import DeleteCheck from "./DeleteCheck";
import { useRouteMatch } from "react-router-dom";
import { sourceType, sourceCalcType } from "../helpers/dropdownConstants";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import EditButton from "./EditButton";
import SourceForm from "./SourceForm";

const SourceTable = ({ data, edit = false, global = true }) => {
  const { SearchBar } = Search;
  const { instance, accounts } = useMsal();
  const urlMatch = useRouteMatch();
  const projectId = urlMatch.params.projectId;
  const [editSource, setEditSource] = useState({ open: false });

  const columns = [
    {
      dataField: "name",
      text: "Source Name",
    },
    {
      dataField: "fuel_type",
      formatter: (cell, row) => row.fuel_type && row.fuel_type.name,
      text: "Fuel Type",
    },
    {
      dataField: "source_type",
      formatter: (cell, row) => sourceType[row.source_type],
      text: "Function",
    },
    {
      dataField: "efficiency",
      text: "Efficiency",
    },
    {
      dataField: "calc_type",
      formatter: (cell, row) => sourceCalcType[row.calc_type],
      text: "Calculation Type",
    },
    {
      dataField: "edit",
      text: "",
      formatter: (cell, row) => (
        <div style={{ marginLeft: "30px" }}>
          <EditButton
            disabled={
              global && !accounts[0].idTokenClaims.roles.includes("Admin.Write")
            }
            handleEdit={() => {
              setEditSource({ open: true, existing: row });
            }}
          />
        </div>
      ),
      isDummyField: true,
    },
    {
      dataField: "deletefield",
      text: "",
      hidden: !edit,
      formatter: (cell, row) => (
        <DeleteCheck
          id={row.id}
          queryKey={global ? "global_sources" : ["sources", projectId]}
          type={global ? "global_sources" : "sources"}
          context={global ? "global source" : "source"}
        />
      ),
      isDummyField: true,
    },
  ];
  return (
    <ToolkitProvider keyField="id" data={data} columns={columns} search>
      {(props) => (
        <div>
          <SearchBar {...props.searchProps} style={{ width: "300px" }} />
          <br />
          <BootstrapTable
            {...props.baseProps}
            bordered={false}
            hover
            noDataIndication="There are no templates available"
          />
          <Modal
            centered
            show={editSource.open}
            dialogClassName="modal-100w"
            onHide={() => setEditSource({ ...editSource, open: false })}
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit Source</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {editSource.existing && (
                <SourceForm
                  method="PATCH"
                  global={global}
                  projectId={projectId}
                  URL={
                    global
                      ? `/api/global_sources/${editSource.existing.id}/`
                      : `/api/sources/${editSource.existing.id}/`
                  }
                  accounts={accounts}
                  instance={instance}
                  initialValues={{
                    name: editSource.existing.name,
                    fuel_type: {
                      label: editSource.existing.fuel_type.name,
                      value: editSource.existing.fuel_type.url,
                    },
                    source_type: editSource.existing.source_type,
                    efficiency: editSource.existing.efficiency,
                    calc_type: editSource.existing.calc_type,
                  }}
                  queryKey={global ? "global_sources" : ["sources", projectId]}
                  setForm={(state) => setEditSource({ open: state })}
                />
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setEditSource({ ...editSource, open: false })}
              >
                Cancel
              </Button>
              <Button variant="primary" type="submit" form="source-edit-form">
                Save
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </ToolkitProvider>
  );
};

export default SourceTable;
