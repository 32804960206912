import { useMutation, useQueryClient, QueryKey } from "react-query";
import { useMsal } from "@azure/msal-react";
import { callPatch } from "../auth/callApi";

type UpdateProps<F> = {
  id: string;
  data: F;
};

export const useUpdate = <F extends object, T>(
  model: string,
  key: QueryKey,
  callBack?: () => void
) => {
  const queryClient = useQueryClient();
  const { instance } = useMsal();
  const mutation = useMutation<T, unknown, UpdateProps<F>>(
    ({ id, data }) =>
      callPatch(
        `${process.env.REACT_APP_URL}/api/${model}/${id}/`,
        data,
        instance
      ),
    {
      mutationKey: key,
      onSuccess: () => {
        queryClient.invalidateQueries(key);
        callBack && callBack();
      },
    }
  );

  return mutation;
};
