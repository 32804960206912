import { useRef, useState, useCallback, useMemo } from "react";
import ReactMap, { Source, Layer, Marker } from "react-map-gl";
import bbox from "@turf/bbox";
import Pin from "./Pin";
import { MdBolt } from "react-icons/md";
import { useElectricalBoards } from "queries";
import { useRouteMatch } from "react-router-dom";

const layerStyle = {
  id: "Buildings",
  type: "fill",
  paint: {
    "fill-color": [
      "case",
      ["boolean", ["feature-state", "selected"], false],
      "#E66100",
      "#5D3A9B",
    ],
  },
};

const InfrastructureMapper = ({
  geoData,
  infrastructureCoords,
  setInfrastructureCoords,
  method,
  boardId,
}) => {
  const [cursor, setCursor] = useState("auto");
  const urlMatch = useRouteMatch();
  const mapRef = useRef();
  const siteId = urlMatch.params.siteId;

  const onMarkerDrag = useCallback((event) => {
    setInfrastructureCoords({
      longitude: event.lngLat.lng,
      latitude: event.lngLat.lat,
    });
  }, []);

  const setupMap = () => {
    // Auto-zoom to the extent of the data
    if (geoData) {
      const [minLng, minLat, maxLng, maxLat] = bbox(geoData);
      method === "POST" &&
        setInfrastructureCoords({
          latitude: (minLat + maxLat) / 2,
          longitude: (minLng + maxLng) / 2,
        });
      mapRef.current.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        { padding: 40, duration: 0 }
      );
    } else {
      if (method === "POST") {
        mapRef.current.transform &&
          setInfrastructureCoords({
            latitude: mapRef.current.transform._center.lat,
            longitude: mapRef.current.transform._center.lng,
          });
      } else {
        const minLng = infrastructureCoords.longitude - 0.00241583213;
        const maxLng = infrastructureCoords.longitude + 0.00241583213;
        const minLat = infrastructureCoords.latitude - 0.0047780660675158515;
        const maxLat = infrastructureCoords.latitude + 0.0047780660675158515;

        mapRef.current.fitBounds(
          [
            [minLng, minLat],
            [maxLng, maxLat],
          ],
          { padding: 40, duration: 1500 }
        );
      }
    }
    // hide the buildings layer
    mapRef.current.setLayoutProperty("building", "visibility", "none");
    mapRef.current.setLayoutProperty("building-line", "visibility", "none");
  };

  const { boards } = useElectricalBoards(siteId)

  const electricalMarkers = useMemo(
    () =>
      boards &&
      boards.filter(board => board.id !== boardId).map((board) => (
        <Marker
          key={board.id}
          latitude={board.coordinates.latitude}
          longitude={board.coordinates.longitude}
          anchor="bottom"
          style={{ cursor: "pointer" }}
        >
          <MdBolt size={24} />
        </Marker>
      )),
    [boards, boardId]
  );


  return (
    <div>
      <ReactMap
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        initialViewState={{
          latitude: 51.508,
          longitude: -0.1281,
          zoom: 8,
        }}
        style={{ width: 330, height: 242 }}
        mapStyle="mapbox://styles/mapbox/streets-v9"
        onMouseEnter={() => setCursor("pointer")}
        onMouseLeave={() => setCursor("auto")}
        cursor={cursor}
        onLoad={setupMap}
        interactiveLayerIds={[layerStyle.id]}
        ref={(ref) => {
          const map = ref && ref.getMap();
          if (map) {
            mapRef.current = map;
          }
        }}
      >
        {electricalMarkers}
        {infrastructureCoords && (
          <Marker
            longitude={infrastructureCoords.longitude}
            latitude={infrastructureCoords.latitude}
            anchor="bottom"
            draggable
            onDrag={onMarkerDrag}
            className="active-marker"
          >
            <Pin size={24} className="active-marker"/>
          </Marker>
        )}
        <Source id="site-data" type="geojson" data={geoData}>
          <Layer {...layerStyle} />
        </Source>
        
      </ReactMap>
    </div>
  );
};

export default InfrastructureMapper;
