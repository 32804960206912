import { ElectricalNetwork } from "types";

function flatten(network: ElectricalNetwork[]) {
  return network.reduce((acc: ElectricalNetwork[], x: ElectricalNetwork) => {
    acc = acc.concat(x);
    if (x.children) {
      acc = acc.concat(flatten(x.children));
      x.children = [];
    }
    return acc;
  }, []);
}

export const sankeyConvert = ({ data }: { data: ElectricalNetwork }) => {
  let source: number[] = [];
  let target: number[] = [];
  let value: number[] = [];
  let label: string[] = [];
  let color: string[] = [];

  if (!data.children || data.children.length < 1) {
    return null;
  }

  const flatData = flatten([data]);

  flatData.forEach((board, index) => {
    if (board.parent) {
      source.push(
        flatData.findIndex((findBoard) => findBoard.id === board.parent)
      );
      target.push(index);
      value.push(board.attributes.load);
    }
    if (board.attributes.isBuilding) {
      color.push("#69be94");
    } else {
      color.push("#343a40");
    }
    label.push(board.name);
  });

  return {
    link: { source: source, target: target, value: value },
    node: {
      pad: 15,
      thickness: 30,
      line: {
        color: "black",
        width: 0,
      },
      label: label,
      color: color,
    },
  };
};
