import { useQuery } from "react-query";
import { Project } from "../types";
import { useMsal } from "@azure/msal-react";
import { callGet } from "../auth/callApi";

export const useProjects = () => {
  const { instance } = useMsal();
  const { data, isLoading, error } = useQuery<Project[]>("projects", () =>
    callGet(`${process.env.REACT_APP_URL}/api/projects`, instance)
  );
  return {
    projects: data,
    isLoading,
    error,
  };
};

export const useProject = (projectID?: number) => {
  const { instance } = useMsal();
  const { data, isLoading, error } = useQuery<Project>(
    ["project", projectID],
    () =>
      callGet(
        `${process.env.REACT_APP_URL}/api/projects/${projectID}`,
        instance
      ),
    {
      enabled: !!projectID,
    }
  );
  return {
    project: data,
    isLoading,
    error,
  };
};
