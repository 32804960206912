import Breadcrumbs from "components/Breadcrumbs";
import Loader from "components/Loader";
import NetworkControl from "components/NetworkControl";
import { useElectricalNetwork } from "queries";
import Tree from "react-d3-tree";
import { useRouteMatch } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { MdHome, MdBolt, MdAdd, MdEdit } from "react-icons/md";
import Dropdown from "react-bootstrap/Dropdown";
import { useState, useEffect } from "react";
import { ShowModal, ElectricalNetwork, Building } from "types";
import ModalFormWrapper from "components/ModalFormWrapper";
import ElectricalBoardForm from "forms/ElectricalBoardForm";
import Button from "react-bootstrap/Button";
import ConnectBuildingForm from "forms/ConnectBuildingForm";
import DeleteCheck from "components/DeleteCheck";
import { sankeyConvert } from "helpers/sankeyConvert";
import Sankey from "components/Sankey";

const ElectricalNetworkPage: React.FunctionComponent = () => {
  const [addBoard, setAddBoard] = useState<ShowModal<ElectricalNetwork>>({
    open: false,
  });
  const [editBoard, setEditBoard] = useState<ShowModal<number>>({
    open: false,
  });
  const [connectBuilding, setConnectBuilding] = useState<ShowModal<Building[]>>(
    {
      open: false,
    }
  );
  const [diagram, setDiagram] = useState<"network" | "sankey">("network");

  const urlMatch: any = useRouteMatch();
  const siteId = urlMatch.params.siteId;

  const { graphData, isLoading } = useElectricalNetwork(siteId);
  // to be used soon
  const [sankeyData, setSankeyData] = useState<any>();

  useEffect(() => {
    if (graphData) {
      setSankeyData(
        sankeyConvert({ data: JSON.parse(JSON.stringify(graphData)) })
      );
    }
  }, [graphData]);

  if (isLoading) {
    return <Loader />;
  }

  const iconStyle = {
    fontSize: "1.4em",
    marginBottom: "0.2em",
    marginLeft: "auto",
    marginRight: "0.3em",
  };

  return (
    <div>
      <Breadcrumbs urlMatch={urlMatch} />
      <div style={{ display: "flex", alignItems: "center" }}>
        <h2 className="categoryheader">Electrical Diagrams</h2>
        <NetworkControl diagram={diagram} setDiagram={setDiagram} />
      </div>
      {sankeyData && diagram === "sankey" && <Sankey sankeyData={sankeyData} />}
      {graphData && diagram === "network" && (
        <div
          id="treeWrapper"
          style={{
            height: "80vh",
            width: "calc(100vw - 20px)",
            marginLeft: "calc(-50vw + 50% + 10px)",
          }}
        >
          <Tree
            data={graphData}
            orientation="vertical"
            collapsible={false}
            separation={{ siblings: 2, nonSiblings: 3 }}
            depthFactor={220}
            pathFunc={"step"}
            translate={{ x: 900, y: 100 }}
            zoom={0.7}
            renderCustomNodeElement={(nodeData) => {
              const isBuilding = nodeData.nodeDatum?.attributes?.isBuilding;
              const isLeaf = nodeData.nodeDatum?.attributes?.isLeaf;
              const hasBuildingChildren =
                nodeData.nodeDatum?.attributes?.hasBuildingChildren;
              return (
                <g>
                  <foreignObject width={240} height={250} x={-120} y={-80}>
                    <div
                      className="node"
                      style={{
                        backgroundColor: isBuilding ? "#69be94" : "white",
                        padding: "10px",
                        borderRadius: "5px",
                        filter: "drop-shadow( 3px 3px 4px lightgrey)",
                        width: "90%",
                        margin: "auto",
                        marginTop: "10px",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <p style={{ color: "black", marginBottom: "5px" }}>
                          <b>{nodeData.nodeDatum.name}</b>
                        </p>
                        {isBuilding ? (
                          <MdHome style={iconStyle} />
                        ) : (
                          <MdBolt style={{ ...iconStyle }} />
                        )}
                      </div>
                      <p>
                        {isBuilding ? "Demand" : "Diversified Load"}:{" "}
                        {nodeData.nodeDatum?.attributes?.load} kW
                      </p>
                      {!isBuilding && (
                        <p style={{ marginTop: "-10px" }}>
                          {"Diversity"}:{" "}
                          {nodeData.nodeDatum?.attributes?.diversity}
                        </p>
                      )}
                      {!isBuilding ? (
                        <div style={{ display: "flex" }}>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="outline-primary"
                              id="connect"
                              size="sm"
                            >
                              <MdAdd
                                style={{
                                  marginTop: "-5px",
                                  marginRight: "5px",
                                  fontSize: "1.4em",
                                }}
                              />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => {
                                  setAddBoard({
                                    open: true,
                                    parent: nodeData.nodeDatum?.attributes
                                      ?.id as number,
                                  });
                                }}
                              >
                                Electrical Board
                              </Dropdown.Item>
                              <Dropdown.Item
                                disabled={!isLeaf && !hasBuildingChildren}
                                onClick={() => {
                                  const buildingList: unknown =
                                    nodeData.nodeDatum?.attributes?.buildings;
                                  if (buildingList) {
                                    setConnectBuilding({
                                      open: true,
                                      parent: nodeData.nodeDatum?.attributes
                                        ?.id as number,
                                      existing: buildingList as Building[],
                                    });
                                  }
                                }}
                              >
                                Building
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                          <Button
                            variant="outline-primary"
                            size="sm"
                            style={{
                              marginLeft: "auto",
                              marginRight: "0.4em",
                            }}
                            onClick={() => {
                              setEditBoard({
                                open: true,
                                existing: nodeData.nodeDatum.attributes
                                  ?.id as number,
                              });
                            }}
                          >
                            <MdEdit />
                          </Button>
                          <DeleteCheck
                            id={nodeData.nodeDatum?.attributes?.id as number}
                            queryKey={["electrical_network", siteId]}
                            type="electrical_boards"
                            context="electrical board"
                            disabled={false}
                            size="sm"
                            description={
                              <>
                                <div>
                                  All child electrical boards will also be
                                  deleted
                                </div>
                              </>
                            }
                          />
                        </div>
                      ) : (
                        <div style={{ display: "flex" }}>
                          <LinkContainer
                            to={`${
                              urlMatch.url.split("/electrical_network/")[0]
                            }/building/${nodeData.nodeDatum.attributes?.id}/${
                              nodeData.nodeDatum.name
                            }`}
                            style={{ marginLeft: "auto" }}
                          >
                            <Button size="sm" variant="outline-dark">
                              View
                            </Button>
                          </LinkContainer>
                        </div>
                      )}
                    </div>
                  </foreignObject>
                </g>
              );
            }}
          />
        </div>
      )}

      <ModalFormWrapper
        show={addBoard}
        setShow={setAddBoard}
        context="Electrical Board"
        action="create"
        formId="electrical-form"
      >
        <ElectricalBoardForm
          method="create"
          parent={addBoard.parent}
          setClose={() => setAddBoard({ open: false })}
        />
      </ModalFormWrapper>
      <ModalFormWrapper
        show={editBoard}
        setShow={setEditBoard}
        context="Electrical Board"
        action="update"
        formId="electrical-edit-form"
      >
        <ElectricalBoardForm
          method="update"
          setClose={() => setEditBoard({ open: false })}
          updateId={editBoard.existing}
        />
      </ModalFormWrapper>
      <ModalFormWrapper
        show={connectBuilding}
        setShow={setConnectBuilding}
        context="Connected Buildings"
        action="update"
        formId="connect-buildings-form"
      >
        {connectBuilding.parent && connectBuilding.existing && (
          <ConnectBuildingForm
            setClose={() => setConnectBuilding({ open: false })}
            boardId={connectBuilding.parent}
            existingBuildings={connectBuilding.existing}
          />
        )}
      </ModalFormWrapper>
    </div>
  );
};

export default ElectricalNetworkPage;
