import { useQuery } from "react-query";
import { ElectricalNetwork } from "../types";
import { useMsal } from "@azure/msal-react";
import { callGet } from "../auth/callApi";

export const useElectricalNetwork = (siteId?: string) => {
  const { instance } = useMsal();
  const { data, isLoading, error } = useQuery<ElectricalNetwork>(
    ["electrical_network", siteId],
    () =>
      callGet(
        `${process.env.REACT_APP_URL}/api/electrical_boards/network?site=${siteId}`,
        instance
      ),
    {
      enabled: !!siteId,
    }
  );
  return {
    graphData: data,
    isLoading,
    error,
  };
};
