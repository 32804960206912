import { useMutation, useQueryClient, QueryKey } from "react-query";
import { useMsal } from "@azure/msal-react";
import { callDelete } from "../auth/callApi";

export const useDelete = (
  model: string,
  key: QueryKey,
  callBack?: () => void
) => {
  const queryClient = useQueryClient();
  const { instance } = useMsal();
  const mutation = useMutation<unknown, unknown, string>(
    (id) =>
      callDelete(`${process.env.REACT_APP_URL}/api/${model}/${id}/`, instance),
    {
      mutationKey: key,
      onSuccess: () => {
        queryClient.invalidateQueries(key);
        callBack && callBack();
      },
    }
  );

  return mutation;
};
