import React from "react";
import Chart from "./Chart";
import { ThreeDots } from "react-loader-spinner";
import ResultTable from "./ResultTable";

const ResultCharts = ({ calculateMutation }) => {
  const { data, isLoading, error } = calculateMutation;

  if (isLoading) {
    return (
      <ThreeDots className="loader" color="#69be94" height={100} width={100} />
    );
  }
  if (error) {
    return <p>Error calculating: {error}</p>;
  }

  return (
    <div>
      {data && (
        <div>
          <Chart data={data} type={"heating"} label={"Heating"} />
          <Chart data={data} type={"cooling"} label={"Cooling"} />
          <Chart data={data} type={"humidification"} label={"Humidification"} />
        </div>
      )}
      {data && <ResultTable data={data} />}
    </div>
  );
};

export default ResultCharts;
