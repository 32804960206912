import { getToken } from "./getToken";
import { IPublicClientApplication } from "@azure/msal-browser";

export const callGet = async (
  url: string,
  instance: IPublicClientApplication
) => {
  const token = await getToken(instance);
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  });

  return response.json();
};

export const callPost = async (
  url: string,
  body: object,
  instance: IPublicClientApplication
) => {
  const token = await getToken(instance);
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });

  return response.json();
};

export const callPatch = async (
  url: string,
  body: object,
  instance: IPublicClientApplication
) => {
  const token = await getToken(instance);
  const response = await fetch(url, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });

  return response.json();
};

export const callDelete = async (
  url: string,
  instance: IPublicClientApplication
) => {
  const token = await getToken(instance);
  const response = await fetch(url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  });

  return response;
};
