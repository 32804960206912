import { useQuery } from "react-query";
import { Building } from "../types";
import { useMsal } from "@azure/msal-react";
import { callGet } from "../auth/callApi";

export const useBuildings = (siteId?: string) => {
  const { instance } = useMsal();
  const { data, isLoading, error } = useQuery<Building[]>(
    ["buildings", siteId],
    () =>
      callGet(
        `${process.env.REACT_APP_URL}/api/buildings?site=${siteId}`,
        instance
      ),
    {
      enabled: !!siteId,
    }
  );
  return {
    buildings: data,
    isLoading,
    error,
  };
};

export const useBuilding = (buildingId?: string) => {
  const { instance } = useMsal();
  const { data, isLoading, error } = useQuery<Building>(
    ["building", buildingId],
    () =>
      callGet(
        `${process.env.REACT_APP_URL}/api/buildings/${buildingId}`,
        instance
      ),
    {
      enabled: !!buildingId,
    }
  );
  return {
    building: data,
    isLoading,
    error,
  };
};
