import React from "react";
import Table from "react-bootstrap/Table";
import { ThreeDots } from "react-loader-spinner";

const RainfallResults = ({ calculateMutation }) => {
  const { data, isLoading, error } = calculateMutation;

  if (isLoading) {
    return (
      <ThreeDots className="loader" color="#69be94" height={100} width={100} />
    );
  }
  if (error) {
    return <p>Error calculating: {error}</p>;
  }

  return (
    <div>
      {data && (
        <div style={{ marginTop: "40px" }}>
          <h4 className="results_header">Calculation Results </h4>
          <Table style={{ marginBottom: "40px" }}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Rainfall Intensity (mm/hr)</td>
                <td>{data.rain_intensity.toLocaleString() + " "}</td>
              </tr>
              <tr>
                <td>
                  Total Roof Area (M<sub>2</sub>)
                </td>
                <td>{data.roof_area.toLocaleString() + " "}</td>
              </tr>
              <tr>
                <td>Peak Rainfall (l/s)</td>
                <td>{data.peak_rainfall.toLocaleString() + " "}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      )}
    </div>
  );
};

export default RainfallResults;
