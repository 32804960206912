import { apiRequest } from "./authConfig";

const authToken = async (instance, accounts) => {
  const token = await instance.acquireTokenSilent({
    ...apiRequest,
    account: accounts[0],
  });
  let bearer = "Bearer " + token.accessToken;
  return bearer;
};

export default authToken;
