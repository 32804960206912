import React from 'react'
import Table from "react-bootstrap/Table"

const ResultTable = ({data}) => {
  return (
    <div style={{marginTop: "40px"}}>
      <h4 className="results_header">Energy</h4>
      <Table striped bordered style={{marginBottom: "40px"}}>
        <thead>
          <tr>
            <th>Source</th>
            <th>Total (/annum)</th>
            <th>Max (hrly)</th>
            <th>Avg (hrly)</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(data.energy_sums).map(source => (
            <tr key={source}>
              <td style={{width: "300px"}}>
                {source.replace(/(^|\s)\S/g, letter => letter.toUpperCase())}
              </td>
              <td style={{width: "300px"}}>
                {(data.energy_sums[source]).toLocaleString() + " "}
                kWhr
              </td>
              <td style={{width: "250px"}}>
                {(data.energy_maxs[source]).toLocaleString() + " "}
                kWhr
              </td>
              <td style={{width: "250px"}}>
                {(data.energy_means[source]).toLocaleString() + " "}
                kWhr
              </td>
            </tr>
          ))}
          <tr>
            <td><b>Totals</b></td>
            <td><b>{(data.total_energy).toLocaleString() + " "}kWhr</b></td>
            <td><b>{(data.total_max_energy).toLocaleString() + " "}kWhr</b></td>
            <td><b>{(data.total_mean_energy).toLocaleString() + " "}kWhr</b></td>
          </tr>
        </tbody>
      </Table>
      <h4 className="results_header">Carbon</h4>
      <Table striped bordered style={{marginBottom: "40px"}}>
        <thead>
          <tr>
            <th>Source</th>
            <th>Total (/annum)</th>
            <th>Max (hrly)</th>
            <th>Avg (hrly)</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(data.carbon_sums).map(source => (
            <tr key={source}>
              <td style={{width: "300px"}}>
                {source.replace(/(^|\s)\S/g, letter => letter.toUpperCase()).replace("_", " ")}
              </td>
              <td style={{width: "300px"}}>
                {(data.carbon_sums[source]).toLocaleString() + " "}
                kgCO<sub>2</sub>
              </td>
              <td style={{width: "250px"}}>
                {(data.carbon_maxs[source]).toLocaleString() + " "}
                kgCO<sub>2</sub>
              </td>
              <td style={{width: "250px"}}>
                {(data.carbon_means[source]).toLocaleString() + " "}
                kgCO<sub>2</sub>
              </td>
            </tr>
          ))}
          <tr>
            <td><b>Totals</b></td>
            <td><b>{(data.total_carbon).toLocaleString() + " "}kgCO<sub>2</sub></b></td>
            <td><b>{(data.total_max_carbon).toLocaleString() + " "}kgCO<sub>2</sub></b></td>
            <td><b>{(data.total_mean_carbon).toLocaleString() + " "}kgCO<sub>2</sub></b></td>
          </tr>
        </tbody>
      </Table>
      <h4 className="results_header">Extra Values </h4>
      <Table style={{marginBottom: "40px"}}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Average Airflow Volume</td>
            <td>{(data.airflow_vol).toLocaleString() + " "}m<sup>3</sup>/s</td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}

export default ResultTable