import { Geometry } from "geojson";
import { FeatureCollection } from "geojson";
import { SelectedBuilding } from "types";

const filterMapBuildings = (
  featureCollection: FeatureCollection<Geometry, SelectedBuilding>,
  year: number,
  search: string
): FeatureCollection => {
  const features = featureCollection.features.filter((feature) => {
    if (!feature.properties?.id) {
      return true;
    } else if (
      !search ||
      feature.properties.name.toLowerCase().includes(search.toLowerCase())
    ) {
      if (
        year >= parseInt(feature.properties.build_date.split("-")[0]) &&
        year <= parseInt(feature.properties.demo_date.split("-")[0])
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });
  return { type: "FeatureCollection", features };
};

export default filterMapBuildings;
