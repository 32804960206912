import callApi from "./callApi";

const getSources = async (instance, accounts, url = "/api/global_sources") => {
  const res = await callApi(url, "GET", instance, accounts);
  const options = res.map((st) => ({
    value: st.url,
    label: st.name,
    source_type: st.source_type,
  }));
  return options;
};

export default getSources;
