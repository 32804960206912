import { FeatureCollection } from "geojson";
import { Map } from "mapbox-gl";
import { Filters } from "types";

const mapFilterHandler = (
  mapRef: React.MutableRefObject<Map | undefined>,
  filteredGeo: FeatureCollection,
  filters: Filters[]
) => {
  if (mapRef?.current && filteredGeo) {
    if (!filters.includes("building")) {
      mapRef.current.setLayoutProperty("site-data", "visibility", "none");
    } else {
      mapRef.current.setLayoutProperty("site-data", "visibility", "visible");
    }
  }
};

export default mapFilterHandler;
