import { useQuery } from "react-query";
import { ElectricalBoard } from "../types";
import { useMsal } from "@azure/msal-react";
import { callGet } from "../auth/callApi";

export const useElectricalBoards = (siteId?: string) => {
  const { instance } = useMsal();
  const { data, isLoading, error } = useQuery<ElectricalBoard[]>(
    ["electrical_boards"],
    () =>
      callGet(
        `${process.env.REACT_APP_URL}/api/electrical_boards/?site=${siteId}`,
        instance
      ),
    { enabled: !!siteId }
  );
  return {
    boards: data,
    isLoading,
    error,
  };
};

export const useElectricalBoard = (boardId?: number) => {
  const { instance } = useMsal();
  const { data, isLoading, error } = useQuery<ElectricalBoard[]>(
    ["electrical_board", boardId],
    () =>
      callGet(
        `${process.env.REACT_APP_URL}/api/electrical_boards/${boardId}`,
        instance
      ),
    {
      enabled: !!boardId,
    }
  );
  return {
    board: data,
    isLoading,
    error,
  };
};
