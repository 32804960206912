import React from "react";
import { useState } from "react";
import { LinkContainer } from "react-router-bootstrap";
import ProjectForm from "../components/ProjectForm";
import DeleteCheck from "../components/DeleteCheck";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Breadcrumbs from "../components/Breadcrumbs";
import { useRouteMatch } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import Collapse from "react-bootstrap/Collapse";
import { useMsal } from "@azure/msal-react";
import { useProjects } from "../queries";
import EditButton from "../components/EditButton";
import Modal from "react-bootstrap/Modal";
import Loader from "../components/Loader";
import { Person, PersonCardInteraction } from "@microsoft/mgt-react";

const Projects = () => {
  const { instance, accounts } = useMsal();
  const { SearchBar } = Search;
  const urlMatch = useRouteMatch();
  const [projectForm, setProjectForm] = useState(false);
  const [editProject, setEditProject] = useState({ open: false });

  const { projects, isLoading, error } = useProjects();

  // Project Name link to project
  function projectNameLink(cell, row) {
    return (
      <LinkContainer to={{ pathname: `/project/${row.id}/${row.name}` }}>
        <div className="table-link">{row.name}</div>
      </LinkContainer>
    );
  }

  // Delete project buttons
  function deleteFormat(cell, row) {
    return (
      <DeleteCheck
        id={row.id}
        queryKey="projects"
        type="projects"
        disabled={
          accounts[0].username !== row.owner ||
          accounts[0].idTokenClaims.roles.includes("Guest.Read")
        }
        context="projects"
      />
    );
  }

  // Columns for the the project list table
  const columns = [
    {
      dataField: "ref",
      text: "Project Number",
    },
    {
      dataField: "name",
      text: "Name",
      formatter: projectNameLink,
    },
    {
      dataField: "owner",
      text: "Owner",
      formatter: (cell, row) => (
        <Person
          userId={row.owner}
          view={3}
          personCardInteraction={PersonCardInteraction.hover}
          onClick={(e) => e.stopPropagation()}
        />
      ),
    },
    {
      dataField: "edit",
      text: "",
      formatter: (cell, row) => (
        <div style={{ marginLeft: "30px" }}>
          <EditButton
            handleEdit={() => {
              setEditProject({ open: true, existing: row });
            }}
            disabled={accounts[0].idTokenClaims.roles.includes("Guest.Read")}
          />
        </div>
      ),
      isDummyField: true,
    },
    {
      dataField: "deletefield",
      text: "",
      formatter: deleteFormat,
      isDummyField: true,
    },
  ];

  if (error) {
    return <span>Error: {error.message}</span>;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="Projects">
      <Breadcrumbs urlMatch={urlMatch} />
      {!accounts[0].idTokenClaims.roles.includes("Guest.Read") && (
        <>
          <Row>
            <Col>
              <h2 className="categoryheader">My Projects</h2>
            </Col>
            <Col md="auto">
              <Button
                className="create-new"
                variant="outline-primary"
                onClick={() => setProjectForm(!projectForm)}
              >
                {projectForm ? "Cancel" : "New Project"}
              </Button>
            </Col>
          </Row>
          <Collapse in={projectForm}>
            <div className="form">
              <ProjectForm
                method="POST"
                URL="/api/projects/"
                initialValues={{
                  name: "",
                  ref: "",
                  security_group: { value: "sec.user", label: "sec.user" },
                }}
                accounts={accounts}
                instance={instance}
                queryKey="projects"
                setForm={setProjectForm}
              />
            </div>
          </Collapse>
          {projects ? (
            <ToolkitProvider
              keyField="ref"
              data={projects.filter(
                (project) => project.owner === accounts[0].username
              )}
              columns={columns}
              search
            >
              {(props) => (
                <div>
                  <SearchBar
                    {...props.searchProps}
                    style={{ width: "300px" }}
                  />
                  <br />
                  <BootstrapTable
                    {...props.baseProps}
                    bordered={false}
                    hover
                    noDataIndication="You haven't created any projects yet, click 'New Project' to make one"
                  />
                </div>
              )}
            </ToolkitProvider>
          ) : null}
        </>
      )}
      <div className="all-projects">
        <h3>All Projects</h3>
        {projects ? (
          <ToolkitProvider
            keyField="ref"
            data={projects}
            columns={columns}
            search
          >
            {(props) => (
              <div>
                <SearchBar {...props.searchProps} style={{ width: "300px" }} />
                <br />
                <BootstrapTable
                  {...props.baseProps}
                  bordered={false}
                  hover
                  noDataIndication="No projects have been created yet, click 'New Project' to make one"
                />
              </div>
            )}
          </ToolkitProvider>
        ) : null}
      </div>
      <Modal
        centered
        show={editProject.open}
        dialogClassName="modal-100w"
        onHide={() => setEditProject({ ...editProject, open: false })}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editProject.existing && (
            <ProjectForm
              method="PATCH"
              modal={true}
              URL={`/api/projects/${editProject.existing.id}/`}
              accounts={accounts}
              instance={instance}
              initialValues={{
                name: editProject.existing.name,
                ref: editProject.existing.ref,
                security_group: {
                  value: editProject.existing.security_group,
                  label: editProject.existing.security_group,
                },
              }}
              queryKey={["projects"]}
              setForm={(state) => setEditProject({ open: state })}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setEditProject({ ...editProject, open: false })}
          >
            Cancel
          </Button>
          <Button variant="primary" type="submit" form="project-edit-form">
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Projects;
