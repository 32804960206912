import React from "react";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import callApi from "../helpers/callApi";
import { useMutation, useQueryClient } from "react-query";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  carbon_factor: yup.number().required("Carbon Factor is required"),
  calorific_value: yup.number().required("Calorific Value is required"),
});

function FuelForm({
  method,
  URL,
  initialValues = {
    name: "",
    carbon_factor: "",
    calorific_value: "",
  },
  parent,
  accounts,
  instance,
  queryKey,
  setForm,
}) {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (values) => callApi(URL, method, instance, accounts, values),
    {
      onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries(queryKey);
        setForm(false);
      },
    }
  );

  return (
    <Formik
      validationSchema={schema}
      onSubmit={async (
        values,
        { setSubmitting, setErrors, setStatus, resetForm }
      ) => {
        values = { ...values, project: parent };
        try {
          mutation.mutate(values);
          resetForm({});
          setStatus({ success: true });
        } catch (error) {
          console.log(error);
          setStatus({ success: false });
          setSubmitting(false);
          setErrors({ submit: error.message });
        }
      }}
      enableReinitialize
      initialValues={initialValues}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        status,
      }) => (
        <Form
          className="projectform"
          onSubmit={handleSubmit}
          id={method === "PATCH" ? "fuel-edit-form" : "fuel-form"}
        >
          <Card style={{ padding: "10px 18px 3px 18px", margin: "15px 0px" }}>
            <Form.Row>
              <Col md="auto">
                <Form.Group md="4" controlId="validationFormik01">
                  <Form.Label>Fuel Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    isInvalid={touched.name && errors.name}
                    onBlur={handleBlur}
                    className="mb-2 mr-sm-2"
                    autoComplete="off"
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {touched.name && errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md="auto">
                <Form.Group md="4" controlId="validationFormik02">
                  <Form.Label>Carbon Factor</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      name="carbon_factor"
                      autoComplete="off"
                      style={{ width: "120px" }}
                      value={values.carbon_factor}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="mb-2 intfields"
                      isInvalid={touched.carbon_factor && errors.carbon_factor}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text className="mb-2 ">
                        kgCO<sub>2</sub>/kWhr
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    {touched.carbon_factor && errors.carbon_factor}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md="auto">
                <Form.Group md="4" controlId="validationFormik03">
                  <Form.Label>Calorific Value</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      name="calorific_value"
                      autoComplete="off"
                      style={{ width: "120px" }}
                      value={values.calorific_value}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="mb-2 intfields"
                      isInvalid={
                        touched.calorific_value && errors.calorific_value
                      }
                    />
                    <InputGroup.Append>
                      <InputGroup.Text className="mb-2 ">
                        MJ/m<sup>3</sup>
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    {touched.calorific_value && errors.calorific_value}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Form.Row>
            {method !== "PATCH" && (
              <Form.Group md="4">
                <Button type="submit" className="mb-2">
                  {method === "POST" ? "Create" : "Save"}
                </Button>
              </Form.Group>
            )}
          </Card>
        </Form>
      )}
    </Formik>
  );
}

export default FuelForm;
