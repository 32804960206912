import Plot from "react-plotly.js";
import { SankeyData } from "types";
type Props = {
  sankeyData: SankeyData;
};

const Sankey: React.FunctionComponent<Props> = ({ sankeyData }) => {
  var data = {
    type: "sankey",
    orientation: "h",
    valuesuffix: " kW",
    ...sankeyData,
  };
  return (
    <div>
      <Plot
        data={[data as any]}
        layout={{ title: "Electrical Load", height: 800 }}
        style={{ width: "100%", height: "100%" }}
        useResizeHandler={true}
      />
    </div>
  );
};

export default Sankey;
