import { PropsWithChildren } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { ShowModal } from "../types";

type Props<T> = {
  show: ShowModal<T>;
  setShow: (show: ShowModal<T>) => void;
  context: string;
  children: React.ReactNode;
  action: "create" | "update";
  formId: string;
};

function ModalFormWrapper<T>({
  show,
  setShow,
  context,
  children,
  action,
  formId,
}: PropsWithChildren<Props<T>>) {
  return (
    <Modal
      show={show.open}
      centered
      dialogClassName="modal-60w"
      onHide={() => setShow({ open: false })}
    >
      <Modal.Header>
        <Modal.Title>
          {action === "create" ? `Add New ${context}` : `Edit ${context}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow({ open: false })}>
          Cancel
        </Button>
        <Button variant="primary" type="submit" form={formId}>
          {action === "create" ? "Create" : "Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalFormWrapper;
