import React from "react";
import Header from "./components/Header";
import "./App.scss";
import Container from "react-bootstrap/Container";
import { Switch, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

// Authentication
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { loginRequest } from "./auth/authConfig";
import { InteractionType } from "@azure/msal-browser";

// Pages
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import Sites from "./pages/Sites";
import Buildings from "./pages/Buildings";
import Spaces from "./pages/Spaces";
import GlobalDistribution from "./pages/GlobalDistribution";
import GlobalFuels from "./pages/GlobalFuels";
import GlobalSources from "./pages/GlobalSources";
import GlobalSpaceTypes from "./pages/GlobalSpaceTypes";
import SpaceTypes from "./pages/SpaceTypes";
import Sources from "./pages/Sources";
import Fuels from "./pages/Fuels";
import Distribution from "./pages/Distribution";
import SiteMap from "./pages/SiteMap";
import Infrastructure from "./pages/Infrastructure";
import ElectricalNetwork from "pages/ElectricalNetwork";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";

const queryClient = new QueryClient();

const App = () => {
  return (
    <main className="main">
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
      >
        <QueryClientProvider client={queryClient}>
          <Header />
          <Container className="main-container" style={{ marginTop: "30px" }}>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/projects" component={Projects} />
              <Route exact path="/global_fuel_types" component={GlobalFuels} />
              <Route exact path="/global_sources" component={GlobalSources} />
              <Route
                exact
                path="/global_demand"
                component={GlobalDistribution}
              />
              <Route
                exact
                path="/global_space_types"
                component={GlobalSpaceTypes}
              />
              <Route
                exact
                path="/project/:projectId/:projectName"
                component={Sites}
              />
              <Route
                exact
                path="/project/:projectId/:projectName/site/:siteId/:siteName/electrical_network"
                component={ElectricalNetwork}
              />
              <Route
                exact
                path="/project/:projectId/:projectName/site/:siteId/:siteName"
                component={Buildings}
              />
              <Route
                exact
                path="/project/:projectId/:projectName/site/:siteId/:siteName/site_map"
                component={SiteMap}
              />
              <Route
                exact
                path="/project/:projectId/:projectName/site/:siteId/:siteName/building/:buildingId/:buildingName"
                component={Spaces}
              />
              <Route
                exact
                path="/project/:projectId/:projectName/site/:siteId/:siteName/external_load/:infrastructureId/:infrastructureName"
                component={Infrastructure}
              />
              <Route
                exact
                path="/project/:projectId/:projectName/space_types"
                component={SpaceTypes}
              />
              <Route
                exact
                path="/project/:projectId/:projectName/sources"
                component={Sources}
              />
              <Route
                exact
                path="/project/:projectId/:projectName/fuel_types"
                component={Fuels}
              />
              <Route
                exact
                path="/project/:projectId/:projectName/demand"
                component={Distribution}
              />
            </Switch>
          </Container>
          {process.env.REACT_APP_ENV === "development" && (
            <ReactQueryDevtools initialIsOpen={false} />
          )}
        </QueryClientProvider>
      </MsalAuthenticationTemplate>
    </main>
  );
};

export default App;
