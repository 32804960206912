import React, { useState } from "react";
import { useQuery } from "react-query";
import { useMsal } from "@azure/msal-react";
import callApi from "../helpers/callApi";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import SpaceTypeTable from "../components/SpaceTypeTable";
import Button from "react-bootstrap/Button";
import SpaceTypeForm from "../components/SpaceTypeForm";
import Collapse from "react-bootstrap/Collapse";
import { useRouteMatch } from "react-router-dom";
import Breadcrumbs from "../components/Breadcrumbs";

const SpaceTypes = () => {
  const { instance, accounts } = useMsal();

  const [spaceTypeForm, setSpaceTypeForm] = useState(false);

  const urlMatch = useRouteMatch();
  const projectId = urlMatch.params.projectId;

  const projectApiURL =
    process.env.REACT_APP_URL + `/api/projects/${urlMatch.params.projectId}/`;

  const { data, status, error } = useQuery(["space_types", projectId], () =>
    callApi(`/api/space_types?project=${projectId}`, "GET", instance, accounts)
  );

  if (status === "error") {
    return <span>Error: {error.message}</span>;
  }

  if (status === "loading") {
    return <span>Loading</span>;
  }
  return (
    <div>
      <Breadcrumbs urlMatch={urlMatch} />
      <Row>
        <Col>
          <h2 className="categoryheader">Project Space Types</h2>
        </Col>
        <Col md="auto">
          <Button
            className="create-new"
            variant="outline-primary"
            onClick={() => setSpaceTypeForm(!spaceTypeForm)}
          >
            {spaceTypeForm ? "Cancel" : "New Space Type"}
          </Button>
        </Col>
      </Row>
      <Collapse in={spaceTypeForm}>
        <div className="form">
          <SpaceTypeForm
            parent={projectApiURL}
            projectId={projectId}
            method="POST"
            URL={`/api/space_types/`}
            accounts={accounts}
            instance={instance}
            queryKey="space_types"
            setForm={setSpaceTypeForm}
            global={false}
          />
        </div>
      </Collapse>
      <Row>
        <SpaceTypeTable data={data} edit={true} global={false} />
      </Row>
    </div>
  );
};

export default SpaceTypes;
