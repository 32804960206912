import React, { useState } from "react";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import callApi from "../helpers/callApi";
import { useMutation, useQueryClient, useQuery } from "react-query";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Select from "react-select";
import getSources from "../helpers/getSources";
import getDistributions from "../helpers/getDistributions";
import TimePicker from "react-bootstrap-time-picker";

function nullToEmptyString(value, originalValue) {
  if (originalValue.label === null) {
    return "";
  }
  return value;
}

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  heat_source: yup.object().required(),
  cool_source: yup.object().required(),
  humid_source: yup.object().required(),
  heat_demand: yup.object().required(),
  cool_demand: yup.object().required(),
  humid_demand: yup.object().required(),
  ACperhr: yup.number().nullable().required("Air Changes is a required field"),
  percent_fresh_air: yup
    .number()
    .nullable()
    .required("Fresh Air is a required field"),
  ventilation_safety_factor: yup
    .number()
    .nullable()
    .required("The Safety Factor is a required field"),
  room_air_temp: yup
    .number()
    .nullable()
    .required("Air Temperature is a required field"),
  room_air_rh: yup
    .number()
    .transform(nullToEmptyString)
    .when("humid_system", {
      is: (humid_system) => {
        try {
          if (humid_system.label === "None") {
            return true;
          }
        } catch {
          return false;
        }
      },
      then: yup.number().nullable(),
      otherwise: yup
        .number()
        .nullable()
        .required("Humidity is a required field"),
    }),
  hws_load: yup.number().nullable().required("HWS Load is a required field"),
  equipment_load: yup
    .number()
    .nullable()
    .required("Equipment Load is a required field"),
  mechanical_load: yup
    .number()
    .nullable()
    .required("Equipment Load is a required field"),
  fabric_loss: yup
    .number()
    .nullable()
    .required("Fabric Loss is a required field"),
  fabric_gain: yup
    .number()
    .nullable()
    .required("Fabric Gain is a required field"),
  equipment_gain: yup
    .number()
    .nullable()
    .required("Equipemnt Gain is a required field"),
  lighting_load: yup
    .number()
    .nullable()
    .required("Lighting Load is a required field"),
  small_power_load: yup
    .number()
    .nullable()
    .required("Small Power Load is a required field"),
  start_time: yup.number().required(),
  end_time: yup.number().required(),
  days: yup.string().required(),
  comments: yup.string(),
});

const dayOptions = [
  { value: 0, label: "Monday" },
  { value: 1, label: "Tuesday" },
  { value: 2, label: "Wednesday" },
  { value: 3, label: "Thursday" },
  { value: 4, label: "Friday" },
  { value: 5, label: "Saturday" },
  { value: 6, label: "Sunday" },
];

function SpaceTypeForm({
  parent,
  URL,
  initialValues = {
    name: "",
    heat_demand: [],
    cool_demand: [],
    humid_demand: [],
    heat_source: [],
    cool_source: [],
    humid_source: [],
    ACperhr: "",
    percent_fresh_air: "",
    ventilation_safety_factor: "",
    room_air_temp: "",
    room_air_rh: "",
    hws_load: "",
    equipment_load: "",
    mechanical_load: "",
    fabric_loss: "",
    fabric_gain: "",
    equipment_gain: "",
    lighting_load: "",
    small_power_load: "",
    days: "0,1,2,3,4",
    start_time: 0,
    end_time: 23,
    comments: "",
  },
  instance,
  accounts,
  projectId,
  method,
  setForm,
  queryKey,
  global = true,
}) {
  const sources = useQuery(["sources", projectId], () =>
    getSources(
      instance,
      accounts,
      global ? `/api/global_sources` : `/api/sources?project=${projectId}`
    )
  );

  const distributions = useQuery(["demands", projectId], () =>
    getDistributions(
      instance,
      accounts,
      global ? `/api/demand?project` : `/api/demand?project=${projectId}`
    )
  );

  const [allTime, setAllTime] = useState(false);

  const queryClient = useQueryClient();

  const mutation = useMutation(
    (values) => callApi(URL, method, instance, accounts, values),
    {
      onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries(queryKey);
        setForm(false);
      },
    }
  );
  return (
    <Formik
      validationSchema={schema}
      enableReinitialize
      onSubmit={async (
        values,
        { setSubmitting, setErrors, setStatus, resetForm }
      ) => {
        values = { ...values, project: parent };
        values.start_time = parseInt(values.start_time / 3600);
        values.end_time = parseInt(values.end_time / 3600);
        values.heat_demand = values.heat_demand.value;
        values.cool_demand = values.cool_demand.value;
        values.humid_demand = values.humid_demand.value;
        values.heat_source = values.heat_source.value;
        values.cool_source = values.cool_source.value;
        values.humid_source = values.humid_source.value;
        if (allTime) {
          values.start_time = 0;
          values.end_time = 23;
        }
        try {
          mutation.mutate(values);
          resetForm({});
          setStatus({ success: true });
        } catch (error) {
          console.log(error);
          setStatus({ success: false });
          setSubmitting(false);
          setErrors({ submit: error.message });
        }
      }}
      initialValues={initialValues}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
        setFieldTouched,
        values,
        touched,
        isValid,
        errors,
        status,
      }) => (
        <Form
          className="siteform"
          id={
            global
              ? method === "PATCH"
                ? "global-space-edit-form"
                : "global-space-form"
              : method === "PATCH"
              ? "space-edit-form"
              : "space-form"
          }
          onSubmit={handleSubmit}
        >
          <Card style={{ padding: "10px 18px 3px 18px", margin: "15px 0px" }}>
            <Form.Row>
              <Col md="3">
                <Form.Group md="4" controlId="validationFormik01">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Name"
                    htmlSize="10"
                    value={values.name}
                    onChange={handleChange}
                    isValid={touched.name && !errors.name}
                    className="mb-2 mr-sm-2"
                    autoComplete="off"
                    isInvalid={!!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
              </Col>
              {sources.data && (
                <>
                  <Col md="3">
                    <Form.Group md="4" controlId="validationFormik15">
                      <Form.Label>Heating Source</Form.Label>
                      <Select
                        name="heat_source"
                        options={sources.data.filter(
                          (source) => source.source_type === "heat"
                        )}
                        defaultValue={initialValues.heat_source}
                        onChange={(selectedOption) => {
                          setFieldValue("heat_source", selectedOption);
                          if (selectedOption.label === "No Heating") {
                            let noneDist = distributions.data.find(
                              (distribution) => distribution.label === "None"
                            );
                            setFieldValue("heat_demand", noneDist);
                          }
                        }}
                        value={sources.data.filter(
                          (option) => option.value === values.heat_source.value
                        )}
                        onBlur={() => setFieldTouched("heat_source")}
                        className={
                          touched.heat_source && errors.heat_source
                            ? "error mb-2 mr-sm-2 selectbox"
                            : "mb-2 mr-sm-2 selectbox"
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {touched.heat_source &&
                          errors.heat_source &&
                          "Please select an option"}
                      </Form.Control.Feedback>
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group md="4" controlId="validationFormik25243">
                      <Form.Label>Cooling Source</Form.Label>
                      <Select
                        name="cool_source"
                        options={sources.data.filter(
                          (source) => source.source_type === "cool"
                        )}
                        defaultValue={initialValues.cool_source}
                        onChange={(selectedOption) => {
                          setFieldValue("cool_source", selectedOption);
                          if (selectedOption.label === "No Cooling") {
                            let noneDist = distributions.data.find(
                              (distribution) => distribution.label === "None"
                            );
                            setFieldValue("cool_demand", noneDist);
                          }
                        }}
                        value={sources.data.filter(
                          (option) => option.value === values.cool_source.value
                        )}
                        onBlur={() => setFieldTouched("cool_source")}
                        className={
                          touched.cool_source && errors.cool_source
                            ? "error mb-2 mr-sm-2 selectbox"
                            : "mb-2 mr-sm-2 selectbox"
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {touched.cool_source &&
                          errors.cool_source &&
                          "Please select an option"}
                      </Form.Control.Feedback>
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group md="4" controlId="validationFormik98975645">
                      <Form.Label>Humidification Source</Form.Label>
                      <Select
                        name="humid_source"
                        options={sources.data.filter(
                          (source) => source.source_type === "humid"
                        )}
                        defaultValue={initialValues.humid_source}
                        onChange={(selectedOption) => {
                          setFieldValue("humid_source", selectedOption);
                          if (selectedOption.label === "No Humidification") {
                            let noneDist = distributions.data.find(
                              (distribution) => distribution.label === "None"
                            );
                            setFieldValue("humid_demand", noneDist);
                          }
                        }}
                        value={sources.data.filter(
                          (option) => option.value === values.humid_source.value
                        )}
                        onBlur={() => setFieldTouched("humid_source")}
                        className={
                          touched.humid_source && errors.humid_source
                            ? "error mb-2 mr-sm-2 selectbox"
                            : "mb-2 mr-sm-2 selectbox"
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {touched.humid_source &&
                          errors.humid_source &&
                          "Please select an option"}
                      </Form.Control.Feedback>
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </>
              )}
            </Form.Row>
          </Card>
          <Card style={{ padding: "10px 18px 3px 18px", margin: "15px 0px" }}>
            <Form.Row>
              {distributions.data && (
                <>
                  <Col md="3">
                    <Form.Group md="4" controlId="validationFormik345354">
                      <Form.Label>Heating Distribution</Form.Label>
                      <Select
                        name="heat_demand"
                        options={distributions.data}
                        defaultValue={initialValues.heat_demand}
                        onChange={(selectedOption) =>
                          setFieldValue("heat_demand", selectedOption)
                        }
                        value={distributions.data.filter(
                          (option) => option.value === values.heat_demand.value
                        )}
                        onBlur={() => setFieldTouched("heat_demand")}
                        className={
                          touched.heat_demand && errors.heat_demand
                            ? "error mb-2 mr-sm-2 selectbox"
                            : "mb-2 mr-sm-2 selectbox"
                        }
                        isDisabled={values.heat_source.label === "No Heating"}
                      />
                      <Form.Control.Feedback type="invalid">
                        {touched.heat_demand &&
                          errors.heat_demand &&
                          "Please select an option"}
                      </Form.Control.Feedback>
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group md="4" controlId="validationFormik43645646">
                      <Form.Label>Cooling Distribution</Form.Label>
                      <Select
                        name="cool_demand"
                        options={distributions.data}
                        defaultValue={initialValues.cool_demand}
                        onChange={(selectedOption) =>
                          setFieldValue("cool_demand", selectedOption)
                        }
                        value={distributions.data.filter(
                          (option) => option.value === values.cool_demand.value
                        )}
                        onBlur={() => setFieldTouched("cool_demand")}
                        className={
                          touched.cool_demand && errors.cool_demand
                            ? "error mb-2 mr-sm-2 selectbox"
                            : "mb-2 mr-sm-2 selectbox"
                        }
                        isDisabled={values.cool_source.label === "No Cooling"}
                      />
                      <Form.Control.Feedback type="invalid">
                        {touched.cool_demand &&
                          errors.cool_demand &&
                          "Please select an option"}
                      </Form.Control.Feedback>
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group md="4" controlId="validationFormik2354356">
                      <Form.Label>Humidification Distribution</Form.Label>
                      <Select
                        name="humid_demand"
                        options={distributions.data}
                        defaultValue={initialValues.humid_demand}
                        onChange={(selectedOption) =>
                          setFieldValue("humid_demand", selectedOption)
                        }
                        value={distributions.data.filter(
                          (option) => option.value === values.humid_demand.value
                        )}
                        onBlur={() => setFieldTouched("humid_demand")}
                        className={
                          touched.humid_demand && errors.humid_demand
                            ? "error mb-2 mr-sm-2 selectbox"
                            : "mb-2 mr-sm-2 selectbox"
                        }
                        isDisabled={
                          values.humid_source.label === "No Humidification"
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {touched.humid_demand &&
                          errors.humid_demand &&
                          "Please select an option"}
                      </Form.Control.Feedback>
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </>
              )}
            </Form.Row>
          </Card>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <Card style={{ padding: "10px 18px 3px 18px", width: "36%" }}>
              <Form.Row>
                <Col md="auto">
                  <Form.Group md="4" controlId="validationFormik08">
                    <Form.Label>Air Temperature</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="room_air_temp"
                        autoComplete="off"
                        style={{ width: "120px" }}
                        value={values.room_air_temp}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="mb-2 intfields"
                        isInvalid={
                          touched.room_air_temp && errors.room_air_temp
                        }
                      />
                      <InputGroup.Append>
                        <InputGroup.Text className="mb-2 ">°C</InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">
                      {touched.room_air_temp && errors.room_air_temp}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md="auto">
                  <Form.Group md="4" controlId="validationFormik09">
                    <Form.Label>Air Relative Humidity</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="room_air_rh"
                        autoComplete="off"
                        style={{ width: "100px" }}
                        value={values.room_air_rh}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="mb-2 intfields"
                        // disabled={
                        // 	values.humid_system.label === "None" ? true : false
                        // }
                        // placeholder={
                        // 	values.humid_system.label === "None" ? "N/A" : ""
                        // }
                        isInvalid={touched.room_air_rh && errors.room_air_rh}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text className="mb-2 ">%</InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">
                      {touched.room_air_rh && errors.room_air_rh}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Form.Row>
            </Card>
            <Card
              style={{
                padding: "10px 18px 3px 18px",
                marginLeft: "15px",
                width: "65%",
              }}
            >
              <Form.Row>
                <Col md="auto">
                  <Form.Group md="4" controlId="validationFormik05">
                    <Form.Label>Air Changes</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="ACperhr"
                        autoComplete="off"
                        style={{ width: "60px" }}
                        value={values.ACperhr}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="mb-2 intfields"
                        isInvalid={touched.ACperhr && errors.ACperhr}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text className="mb-2 ">/hr</InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">
                      {touched.ACperhr && errors.ACperhr}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md="auto">
                  <Form.Group md="4" controlId="validationFormik06">
                    <Form.Label>Fresh Air</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="percent_fresh_air"
                        style={{ width: "70px" }}
                        autoComplete="off"
                        value={values.percent_fresh_air}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="mb-2 intfields"
                        isInvalid={
                          touched.percent_fresh_air && errors.percent_fresh_air
                        }
                      />
                      <InputGroup.Append>
                        <InputGroup.Text className="mb-2 ">%</InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">
                      {touched.percent_fresh_air && errors.percent_fresh_air}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md="auto">
                  <Form.Group md="4" controlId="validationFormik07">
                    <Form.Label>Ventilation Safety Factor</Form.Label>
                    <Form.Control
                      type="number"
                      name="ventilation_safety_factor"
                      autoComplete="off"
                      style={{ width: "130px" }}
                      value={values.ventilation_safety_factor}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="mb-2 mr-sm-2 intfields"
                      isInvalid={
                        touched.ventilation_safety_factor &&
                        errors.ventilation_safety_factor
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {touched.ventilation_safety_factor &&
                        errors.ventilation_safety_factor}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Form.Row>
            </Card>
          </div>
          <Card style={{ padding: "10px 18px 3px 18px", margin: "15px 0px" }}>
            <Form.Row>
              <Col md="auto">
                <Form.Group md="4" controlId="validationFormik18">
                  <Form.Label>Fabric Loss</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      name="fabric_loss"
                      style={{ width: "100px" }}
                      autoComplete="off"
                      value={values.fabric_loss}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="mb-2 intfields"
                      isInvalid={touched.fabric_loss && errors.fabric_loss}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text className="mb-2 ">
                        W/m<sup>2</sup>
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    {touched.fabric_loss && errors.fabric_loss}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md="auto">
                <Form.Group md="4" controlId="validationFormik18">
                  <Form.Label>Fabric Gain</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      name="fabric_gain"
                      style={{ width: "100px" }}
                      autoComplete="off"
                      value={values.fabric_gain}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="mb-2 intfields"
                      isInvalid={touched.fabric_gain && errors.fabric_gain}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text className="mb-2 ">
                        W/m<sup>2</sup>
                      </InputGroup.Text>
                    </InputGroup.Append>
                    <Form.Control.Feedback type="invalid">
                      {touched.fabric_gain && errors.fabric_gain}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md="auto">
                <Form.Group md="4" controlId="validationFormik10">
                  <Form.Label>HWS Load</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      name="hws_load"
                      style={{ width: "100px" }}
                      autoComplete="off"
                      value={values.hws_load}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="mb-2 intfields"
                      isInvalid={touched.hws_load && errors.hws_load}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text className="mb-2 ">
                        W/m<sup>2</sup>
                      </InputGroup.Text>
                    </InputGroup.Append>
                    <Form.Control.Feedback type="invalid">
                      {touched.hws_load && errors.hws_load}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md="auto">
                <Form.Group md="4" controlId="validationFormik11">
                  <Form.Label>Equipment Gain</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      name="equipment_gain"
                      style={{ width: "100px" }}
                      autoComplete="off"
                      value={values.equipment_gain}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="mb-2 intfields"
                      isInvalid={
                        touched.equipment_gain && errors.equipment_gain
                      }
                    />
                    <InputGroup.Append>
                      <InputGroup.Text className="mb-2 ">
                        W/m<sup>2</sup>
                      </InputGroup.Text>
                    </InputGroup.Append>
                    <Form.Control.Feedback type="invalid">
                      {touched.equipment_gain && errors.equipment_gain}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Form.Row>
          </Card>
          <Card style={{ padding: "10px 18px 3px 18px" }}>
            <Form.Row>
              <Col md="auto">
                <Form.Group md="4" controlId="validationFormik11">
                  <Form.Label>Lighting Load</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      name="lighting_load"
                      style={{ width: "100px" }}
                      autoComplete="off"
                      value={values.lighting_load}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="mb-2 intfields"
                      isInvalid={touched.lighting_load && errors.lighting_load}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text className="mb-2 ">
                        W/m<sup>2</sup>
                      </InputGroup.Text>
                    </InputGroup.Append>
                    <Form.Control.Feedback type="invalid">
                      {touched.lighting_load && errors.lighting_load}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md="auto">
                <Form.Group md="4" controlId="validationFormik11">
                  <Form.Label>Small Power Load</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      name="small_power_load"
                      style={{ width: "100px" }}
                      autoComplete="off"
                      value={values.small_power_load}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="mb-2 intfields"
                      isInvalid={
                        touched.small_power_load && errors.small_power_load
                      }
                    />
                    <InputGroup.Append>
                      <InputGroup.Text className="mb-2 ">
                        W/m<sup>2</sup>
                      </InputGroup.Text>
                    </InputGroup.Append>
                    <Form.Control.Feedback type="invalid">
                      {touched.small_power_load && errors.small_power_load}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md="auto">
                <Form.Group md="4" controlId="validationFormik11">
                  <Form.Label>Equipment Load</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      name="equipment_load"
                      style={{ width: "100px" }}
                      autoComplete="off"
                      value={values.equipment_load}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="mb-2 intfields"
                      isInvalid={
                        touched.equipment_load && errors.equipment_load
                      }
                    />
                    <InputGroup.Append>
                      <InputGroup.Text className="mb-2 ">
                        W/m<sup>2</sup>
                      </InputGroup.Text>
                    </InputGroup.Append>
                    <Form.Control.Feedback type="invalid">
                      {touched.equipment_load && errors.equipment_load}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md="auto">
                <Form.Group md="4" controlId="validationFormik11">
                  <Form.Label>Mechanical Load</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      name="mechanical_load"
                      style={{ width: "100px" }}
                      autoComplete="off"
                      value={values.mechanical_load}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="mb-2 intfields"
                      isInvalid={
                        touched.mechanical_load && errors.mechanical_load
                      }
                    />
                    <InputGroup.Append>
                      <InputGroup.Text className="mb-2 ">
                        W/m<sup>2</sup>
                      </InputGroup.Text>
                    </InputGroup.Append>
                    <Form.Control.Feedback type="invalid">
                      {touched.mechanical_load && errors.mechanical_load}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Form.Row>
          </Card>
          <div style={{ display: "flex" }}>
            <div style={{ width: "60%" }}>
              <Card
                style={{ padding: "10px 18px 3px 18px", marginTop: "15px" }}
              >
                <Form.Row>
                  <Col md="auto">
                    <Form.Group md="4" controlId="validationFormik12">
                      <Form.Label>Operational Days</Form.Label>
                      <Select
                        name="days"
                        placeholder="Select Days..."
                        isMulti={true}
                        options={dayOptions}
                        defaultValue={
                          method === "PATCH"
                            ? initialValues.days
                                .split(",")
                                .map((day) => dayOptions[day])
                            : dayOptions
                        }
                        onChange={(selectedOption) => {
                          setFieldValue(
                            "days",
                            selectedOption
                              .map((option) => option.value)
                              .join(",")
                          );
                        }}
                        className="mb-2 mr-sm-2 selectbox"
                        isValid={touched.days && !errors.days}
                        isInvalid={!!errors.days}
                      />
                    </Form.Group>
                  </Col>

                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      width: "60%",
                    }}
                  >
                    {!allTime && (
                      <>
                        <Col md="auto">
                          <Form.Group md="4" controlId="validationFormik13">
                            <Form.Label>Daily Start Time</Form.Label>
                            <TimePicker
                              format={24}
                              step={60}
                              onChange={(time) => {
                                setFieldValue("start_time", time);
                              }}
                              value={values.start_time}
                            />
                            <Form.Control.Feedback>
                              Looks good!
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col md="auto">
                          <Form.Group md="4" controlId="validationFormik14">
                            <Form.Label>Daily End Time</Form.Label>
                            <TimePicker
                              format={24}
                              step={60}
                              onChange={(time) => {
                                setFieldValue("end_time", time);
                              }}
                              value={values.end_time}
                            />
                            <Form.Control.Feedback>
                              Looks good!
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </>
                    )}

                    <Col md="auto">
                      <Form.Group md="4" controlId="validationFormik14">
                        <Form.Check
                          type="checkbox"
                          id={`select-all`}
                          label={`On 24hr/day`}
                          value={allTime}
                          onChange={() => setAllTime(!allTime)}
                        />
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </div>
                </Form.Row>
              </Card>
            </div>
            <Card
              style={{
                padding: "10px 18px 3px 18px",
                marginLeft: "15px",
                width: "calc(40% - 15px)",
                marginTop: "15px",
              }}
            >
              <Form.Group sd="2" md="5" controlId="validationFormik13">
                <Form.Label>Comments</Form.Label>
                <Form.Control
                  as="textarea"
                  name="comments"
                  style={{ height: "180px" }}
                  autoComplete="off"
                  value={values.comments}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="mb-2"
                  isInvalid={touched.comments && errors.comments}
                />
                <Form.Control.Feedback type="invalid">
                  {touched.comments && errors.comments}
                </Form.Control.Feedback>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
            </Card>
          </div>
          {method === "PATCH" ? (
            ""
          ) : (
            <Col md="auto">
              <Form.Group md="4">
                <Button
                  type="submit"
                  className="mb-2"
                  style={{ marginTop: "32px" }}
                >
                  {method === "POST" ? "Create" : "Save"}
                </Button>
              </Form.Group>
            </Col>
          )}
        </Form>
      )}
    </Formik>
  );
}

export default SpaceTypeForm;
