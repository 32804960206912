const sourceTypeSelect = [
	{ value: "heat", label: "Heating" },
	{ value: "cool", label: "Cooling" },
	{ value: "humid", label: "Humidification" },
];

const sourceType = {
	heat: "Heating",
	cool: "Cooling",
	humid: "Humidification",
};

const sourceCalcTypeSelect = [
	{ value: "boiler", label: "Boiler" },
	{ value: "plc1", label: "Placeholder 1" },
	{ value: "plc2", label: "Placeholder 2" },
];

const sourceCalcType = {
	boiler: "Boiler",
	plc1: "Placeholder 1",
	plc2: "Placeholder 2",
};

const demandCalcTypeSelect = [
	{ value: "cav", label: "CAV" },
	{ value: "plc2", label: "Placeholder 2" },
	{ value: "plc3", label: "Placeholder 3" },
];

const demandCalcType = {
	cav: "CAV",
	plc1: "Placeholder 1",
	plc2: "Placeholder 2",
};

export {
	sourceTypeSelect,
	sourceType,
	sourceCalcTypeSelect,
	sourceCalcType,
	demandCalcTypeSelect,
	demandCalcType,
};
