import React from "react";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import callApi from "../helpers/callApi";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import { useRouteMatch } from "react-router-dom";
import { useMutation, useQueryClient, useQuery } from "react-query";

function IsJsonString(str) {
  if (!str || str === "") return true;
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  ref: yup.number().required("A Ref No. is required"),
  weather: yup.object().nullable().required("Choose a Weather file"),
  rain_intensity: yup.number().required("Rainfall intensity is required"),
  geojson: yup
    .string()
    .nullable()
    .test("is-json", "Not a valid GeoJSON", IsJsonString),
});

//parts removes some of the fields for the edit function
//submit changes the submit button text depending on context
function SiteForm({
  method,
  modal,
  URL,
  initialValues,
  parent,
  setForm,
  instance,
  accounts,
  queryKey,
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (values) => callApi(URL, method, instance, accounts, values),
    {
      onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries(queryKey);
        setForm(false);
      },
    }
  );

  const urlMatch = useRouteMatch();
  const projectId = urlMatch.params.projectId;

  const { data: weatherOptions } = useQuery("weather-options", () =>
    callApi(`/api/weather?project=${projectId}`, "GET", instance, accounts)
  );

  return (
    <Formik
      validationSchema={schema}
      onSubmit={async (
        values,
        { setSubmitting, setErrors, setStatus, resetForm }
      ) => {
        if (method === "POST") {
          values = { ...values, project: parent }; //add site project to request only for creation
        }
        if (values.geojson === "") {
          values.geojson = null;
        }
        if (values.geojson) {
          values.geojson = JSON.parse(values.geojson);
          // add unique id to each geojson feature if it doesn't exist already
          values.geojson.features?.forEach((feature, index) => {
            if (!feature.id) {
              feature.id = (index + 1).toString();
            }
          });
        }
        values.weather = values.weather.value;

        try {
          mutation.mutate(values);
          resetForm({});
          setStatus({ success: true });
        } catch (error) {
          setStatus({ success: false });
          console.log(error);
          setSubmitting(false);
          setErrors({ submit: error.message });
        }
      }}
      enableReinitialize
      initialValues={initialValues}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        setFieldTouched,
        values,
        touched,
        errors,
      }) => (
        <Form
          className="siteform"
          id={method === "PATCH" ? "site-edit-form" : "site-form"}
          noValidate
          onSubmit={handleSubmit}
        >
          <Card style={{ padding: "20px 18px 0px 18px", margin: "15px 0px" }}>
            <Form.Row>
              <Col md="4">
                <Form.Group md="4" controlId="validationFormik01">
                  <Form.Label>Site Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="mb-2 mr-sm-2"
                    autoComplete="off"
                    isInvalid={touched.name && errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {touched.name && errors.name}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>

                {method === "POST" && (
                  <>
                    <Form.Group md="4" controlId="validationFormik02">
                      <Form.Label>Site Ref</Form.Label>
                      <Form.Control
                        type="text"
                        name="ref"
                        value={values.ref}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="mb-2 mr-sm-2"
                        autoComplete="off"
                        isInvalid={touched.ref && errors.ref}
                      />
                      <Form.Control.Feedback type="invalid">
                        {touched.ref && errors.ref}
                      </Form.Control.Feedback>
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                  </>
                )}
                {weatherOptions && (
                  <Form.Group md="4" controlId="validationFormik03">
                    <Form.Label>Weather File</Form.Label>
                    <Select
                      name="weather"
                      options={weatherOptions.map((st) => ({
                        value: st.url,
                        label: st.name,
                      }))}
                      defaultValue={values.weather}
                      onChange={(selectedOption) =>
                        setFieldValue("weather", selectedOption)
                      }
                      onBlur={() => setFieldTouched("weather")}
                      className={
                        touched.weather && errors.weather
                          ? "error mb-2 mr-sm-2 selectbox"
                          : "mb-2 mr-sm-2 selectbox"
                      }
                      isInvalid={errors.weather && touched.weather}
                    />
                    <Form.Control.Feedback type="invalid">
                      {touched.weather && errors.weather}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                )}
                <Form.Group md="4" controlId="validationFormik10">
                  <Form.Label>Rainfall Intensity</Form.Label>
                  <InputGroup className="mb-2">
                    <Form.Control
                      type="number"
                      name="rain_intensity"
                      autoComplete="off"
                      value={values.rain_intensity}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="mb-2 intfields"
                      isInvalid={
                        touched.rain_intensity && errors.rain_intensity
                      }
                    />
                    <InputGroup.Append>
                      <InputGroup.Text className="mb-2">mm/hr</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    {touched.rain_intensity && errors.rain_intensity}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md="8">
                <Form.Group md="4" controlId="validationFormik04">
                  <Form.Label>GeoJSON</Form.Label>
                  <Form.Control
                    as="textarea"
                    type="text"
                    name="geojson"
                    value={values.geojson}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.geojson && errors.geojson}
                    className="mb-2 mr-sm-2"
                    autoComplete="off"
                    style={{ height: "210px" }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {touched.geojson && errors.geojson}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              {!modal && (
                <Form.Group
                  md="2"
                  style={{ marginLeft: "auto", marginRight: "5px" }}
                >
                  <Button type="submit" className="mb-2">
                    {method === "POST" ? "Create" : "Save"}
                  </Button>
                </Form.Group>
              )}
            </Form.Row>
          </Card>
        </Form>
      )}
    </Formik>
  );
}

export default SiteForm;
