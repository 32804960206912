import mapFilterHandler from "./mapFilterHandler";
import mapColors from "../assets/MapColors";
import bbox from "@turf/bbox";
import { FeatureCollection } from "geojson";
import { Map } from "mapbox-gl";
import { Filters } from "types";

const setupMap = (
  siteGeoJson: FeatureCollection,
  mapRef: React.MutableRefObject<Map | undefined>,
  filters: Filters[]
) => {
  siteGeoJson.features.forEach((feature) => {
    mapRef.current?.setFeatureState(
      { source: "site-data", id: feature.id },
      {
        selected: feature.properties?.id
          ? mapColors.hasBuilding
          : mapColors.noBuilding,
      }
    );
  });

  // Auto-zoom to the extent of the data
  const [minLng, minLat, maxLng, maxLat] = bbox(siteGeoJson);
  mapRef.current?.fitBounds(
    [
      [minLng, minLat],
      [maxLng, maxLat],
    ],
    { padding: 40, duration: 1500 }
  );
  mapFilterHandler(mapRef, siteGeoJson, filters);
};

export default setupMap;
