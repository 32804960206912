import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import { electricalNetworkSchema } from "./schemas/electricalNetworkSchema";
import { Coordinate, ElectricalBoard, ElectricalBoardFields } from "types";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Select from "react-select";
import { useElectricalBoards, useSite } from "../queries";
import InfrastructureMapper from "../components/InfrastructureMapper";
import { useRouteMatch } from "react-router-dom";
import { useCreate, useUpdate } from "mutations";

type Props = {
  method: "create" | "update";
  parent?: number;
  setClose: () => void;
  updateId?: number;
};

const ElectricalBoardForm: React.FunctionComponent<Props> = ({
  method,
  parent,
  setClose,
  updateId,
}) => {
  const urlMatch: any = useRouteMatch();
  const siteId = urlMatch.params.siteId;
  const { boards } = useElectricalBoards(siteId);
  const { site } = useSite(siteId);

  const createMutation = useCreate<ElectricalBoardFields, ElectricalBoard>(
    "electrical_boards",
    "electrical_network",
    () => setClose()
  );
  const editMutation = useUpdate<ElectricalBoardFields, ElectricalBoard>(
    "electrical_boards",
    "electrical_network",
    () => setClose()
  );

  let initialValues: ElectricalBoardFields = {
    name: "",
    ref: 0,
    parent: parent,
    site: site?.id || 0,
    coordinates: { latitude: 0, longitude: 0 },
    diversity: 1,
    isRoot: false,
  };
  if (method === "update" && boards) {
    let board = boards.find((board) => board.id === updateId);
    //console.log(board);
    board && (initialValues = { ...board, parent: board.parent?.id });
    console.log(initialValues);
  }
  return (
    <Formik
      validationSchema={electricalNetworkSchema}
      onSubmit={async (values, { setSubmitting, setStatus, resetForm }) => {
        try {
          if (method === "create") {
            createMutation.mutate(values);
          } else {
            updateId &&
              editMutation.mutate({ data: values, id: updateId.toString() });
          }
          resetForm({});
          setStatus({ success: true });
        } catch (error) {
          console.log(error);
          setStatus({ success: false });
          setSubmitting(false);
        }
      }}
      enableReinitialize
      initialValues={initialValues}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        setFieldTouched,
        setFieldValue,
      }) => (
        <Form
          className="electrical-board-form"
          onSubmit={handleSubmit}
          id={method === "update" ? "electrical-edit-form" : "electrical-form"}
        >
          <Card style={{ padding: "20px 18px 0px 18px", margin: "15px 0px" }}>
            <Form.Row>
              <Col md="4">
                <Form.Group controlId="validationFormik01">
                  <Form.Label>Board Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.name && !!errors.name}
                    className="mb-2 mr-sm-2"
                    autoComplete="off"
                  />
                  <Form.Control.Feedback type="invalid">
                    {touched.name && errors.name}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group controlId="validationFormik02">
                  <Form.Label>Board Ref</Form.Label>
                  <Form.Control
                    type="number"
                    name="ref"
                    value={values.ref}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.ref && !!errors.ref}
                    className="mb-2 mr-sm-2"
                    autoComplete="off"
                  />
                  <Form.Control.Feedback type="invalid">
                    {touched.ref && errors.ref}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
              </Col>
              {!initialValues.isRoot && (
                <Col>
                  <Form.Group controlId="validationFormik03">
                    <Form.Label>Parent</Form.Label>
                    {boards && (
                      <Select<ElectricalBoard>
                        isDisabled={initialValues.parent === null}
                        name="parent"
                        options={boards}
                        getOptionLabel={(board) => board.name}
                        getOptionValue={(board) => board.id.toString()}
                        defaultValue={
                          boards.find((board) => board.id === values.parent) ||
                          undefined
                        }
                        onChange={(option) => {
                          setFieldValue("parent", option?.id);
                        }}
                        onBlur={() => setFieldTouched("parent")}
                        className={
                          touched.parent && errors.parent
                            ? "error mb-2 mr-sm-2 selectbox"
                            : "mb-2 mr-sm-2 selectbox"
                        }
                      />
                    )}
                    <Form.Control.Feedback type="invalid">
                      {touched.parent && errors.parent}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              )}
              <Col md="4">
                <Form.Group controlId="validationFormik04">
                  <Form.Label>Diversity</Form.Label>
                  <Form.Control
                    type="number"
                    name="diversity"
                    value={values.diversity}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.diversity && !!errors.diversity}
                    className="mb-2 mr-sm-2"
                    autoComplete="off"
                  />
                  <Form.Control.Feedback type="invalid">
                    {touched.diversity && errors.diversity}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <div
                style={{
                  marginBottom: "25px",
                  width: "100%",
                  marginLeft: "10px",
                }}
              >
                <div style={{ marginBottom: "8px" }}>Board Location</div>
                <p style={{ color: "grey", fontSize: "0.8em" }}>
                  Note: Drag red pointer to approximate location of board
                </p>
                <InfrastructureMapper
                  geoData={site?.geojson}
                  infrastructureCoords={values.coordinates}
                  setInfrastructureCoords={(coordinate: Coordinate) =>
                    setFieldValue("coordinates", coordinate)
                  }
                  method={method === "update" ? "PATCH" : "POST"}
                  boardId={updateId || 0}
                />
              </div>
            </Form.Row>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default ElectricalBoardForm;
