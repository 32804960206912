import React, { useState } from "react";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import callApi from "../helpers/callApi";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import { useMutation, useQueryClient, useQuery } from "react-query";
import Select from "react-select";
import InfrastructureMapper from "./InfrastructureMapper";
import service_types from "../helpers/serviceTypes";

const schema = yup.object().shape({
  name: yup.string().required("A Name is required"),
  ref: yup.number().required("A Ref No. is required"),
  buildings: yup.object().nullable(),
  service_type: yup.string().required("A Service Type is required"),
  load: yup.number().required("Load is a required field"),
});

function InfrastructureForm({
  method,
  URL,
  modal,
  initialValues,
  parent,
  accounts,
  instance,
  queryKey,
  setForm,
  siteMap = false,
  geoData,
  siteId,
}) {
  const queryClient = useQueryClient();

  const [infrastructureCoords, setInfrastructureCoords] = useState(
    initialValues.coordinates ?? null
  );

  const { data: buildingData } = useQuery(["buildings", siteId], () =>
    callApi(`/api/buildings?site=${siteId}`, "GET", instance, accounts)
  );

  const mutation = useMutation(
    (values) => callApi(URL, method, instance, accounts, values),
    {
      onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries(queryKey);
        setForm(false);
      },
    }
  );
  return (
    <Formik
      validationSchema={schema}
      onSubmit={async (
        values,
        { setSubmitting, setErrors, setStatus, resetForm }
      ) => {
        if (method === "POST") {
          values = { ...values, site: parent }; //add building's site to request only for creation
        }
        values.coordinates = infrastructureCoords;
        values.buildings = values.buildings.map((building) => {
          if (building.value) {
            return building.value;
          } else {
            return building.url;
          }
        });
        try {
          mutation.mutate(values);
          resetForm({});
          setStatus({ success: true });
        } catch (error) {
          console.log(error);
          setStatus({ success: false });
          setSubmitting(false);
          setErrors({ submit: error.message });
        }
      }}
      enableReinitialize
      initialValues={initialValues}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        setFieldTouched,
        setFieldValue,
      }) => (
        <Form
          className="infrastructureform"
          noValidate
          onSubmit={handleSubmit}
          id={
            method === "PATCH"
              ? "infrastructure-edit-form"
              : "infrastructure-form"
          }
        >
          <Card style={{ padding: "20px 18px 0px 18px", margin: "15px 0px" }}>
            <Form.Row>
              <Col md="4">
                <Form.Group md="4" controlId="validationFormik01">
                  <Form.Label>Infrastructure Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.name && errors.name}
                    className="mb-2 mr-sm-2"
                    autoComplete="off"
                  />
                  <Form.Control.Feedback type="invalid">
                    {touched.name && errors.name}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>

                {method === "POST" && (
                  <Form.Group md="4" controlId="validationFormik02">
                    <Form.Label>Site Ref</Form.Label>
                    <Form.Control
                      type="text"
                      name="ref"
                      value={values.ref}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="mb-2 mr-sm-2"
                      autoComplete="off"
                      isInvalid={touched.ref && errors.ref}
                    />
                    <Form.Control.Feedback type="invalid">
                      {touched.ref && errors.ref}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                )}
                {buildingData && (
                  <Form.Group md="4" controlId="validationFormik03">
                    <Form.Label>Buildings Served</Form.Label>
                    <Select
                      name="buildings"
                      options={buildingData.map((st) => ({
                        value: st.url,
                        label: st.name,
                      }))}
                      defaultValue={values.buildings.map((building) => ({
                        value: building.url,
                        label: building.name,
                      }))}
                      isMulti
                      onChange={(selectedOption) => {
                        setFieldValue("buildings", selectedOption);
                      }}
                      onBlur={() => setFieldTouched("buildings")}
                      className={
                        touched.buildings && errors.buildings
                          ? "error mb-2 mr-sm-2 selectbox"
                          : "mb-2 mr-sm-2 selectbox"
                      }
                      isInvalid={errors.buildings && touched.buildings}
                    />
                    <Form.Control.Feedback type="invalid">
                      {touched.external_loads && errors.external_loads}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                )}
              </Col>
              <Col>
                <Form.Group md="4" controlId="validationFormik02">
                  <Form.Label>Service Type</Form.Label>
                  <Select
                    name="service_type"
                    options={Object.keys(service_types).map((key) => ({
                      value: key,
                      label: key,
                    }))}
                    selectedOption={values.service_type}
                    defaultValue={{
                      value: values.service_type,
                      label: values.service_type,
                    }}
                    onChange={(selectedOption) =>
                      setFieldValue("service_type", selectedOption.value)
                    }
                    onBlur={() => setFieldTouched("service_type")}
                    className={
                      touched.service_type && errors.service_type
                        ? "error mb-2 mr-sm-2 selectbox"
                        : "mb-2 mr-sm-2 selectbox"
                    }
                    isInvalid={errors.service_type && touched.service_type}
                  />
                  <Form.Control.Feedback type="invalid">
                    {touched.service_type && errors.service_type}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group md="4" controlId="validationFormik10">
                  <Form.Label>Size</Form.Label>
                  <InputGroup className="mb-2">
                    <Form.Control
                      type="number"
                      name="load"
                      style={{ width: "100px" }}
                      autoComplete="off"
                      value={values.load}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="mb-2 intfields"
                      isInvalid={touched.load && errors.load}
                    />
                    {values.service_type && (
                      <InputGroup.Append>
                        <InputGroup.Text className="mb-2 ">
                          {service_types[values.service_type]}
                        </InputGroup.Text>
                      </InputGroup.Append>
                    )}
                    <Form.Control.Feedback type="invalid">
                      {touched.load && errors.load}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Col>
              {!siteMap && (
                <Col>
                  <div style={{ marginBottom: "25px" }}>
                    <div style={{ marginBottom: "8px" }}>
                      Infrastructure Location
                    </div>
                    <InfrastructureMapper
                      geoData={geoData}
                      infrastructureCoords={infrastructureCoords}
                      setInfrastructureCoords={setInfrastructureCoords}
                      method={method}
                    />
                  </div>
                </Col>
              )}
            </Form.Row>
            {!modal && (
              <Form.Group md="4">
                <Button type="submit" className="mb-2">
                  {method === "POST" ? "Create" : "Save"}
                </Button>
              </Form.Group>
            )}
          </Card>
        </Form>
      )}
    </Formik>
  );
}

export default InfrastructureForm;
