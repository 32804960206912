import React from "react";
import "../App.scss";
import { LinkContainer } from "react-router-bootstrap";
import { AiOutlineFundProjectionScreen } from "react-icons/ai";
import Button from "react-bootstrap/Button";
import { GiWindTurbine } from "react-icons/gi";
import { useMsal } from "@azure/msal-react";

const Home = () => {
	const { accounts } = useMsal();
	return (
		<div className="home">
			<h1 className="hometitle">Welcome to SEC</h1>
			{!accounts[0].idTokenClaims.roles.includes("Guest.Read") && (
				<h2>
					<i>A high-level, energy and carbon assessment tool​</i>
				</h2>
			)}
			<LinkContainer to="/projects">
				<Button size="lg" variant="outline-primary">
					<AiOutlineFundProjectionScreen className="projecticon" /> Projects
				</Button>
			</LinkContainer>
			{!accounts[0].idTokenClaims.roles.includes("Guest.Read") && (
				<GiWindTurbine className="windmill" size={800} />
			)}
		</div>
	);
};

export default Home;
