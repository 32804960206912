import React, { useState } from "react";
import { useQuery } from "react-query";
import { useMsal } from "@azure/msal-react";
import callApi from "../helpers/callApi";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import SourceTable from "../components/SourceTable";
import Button from "react-bootstrap/Button";
import SourceForm from "../components/SourceForm";
import Collapse from "react-bootstrap/Collapse";
import { useRouteMatch } from "react-router-dom";
import Breadcrumbs from "../components/Breadcrumbs";

const Sources = () => {
  const { instance, accounts } = useMsal();

  const [sourceForm, setSourceForm] = useState(false);

  const urlMatch = useRouteMatch();
  const projectId = urlMatch.params.projectId;

  const projectApiURL =
    process.env.REACT_APP_URL + `/api/projects/${urlMatch.params.projectId}/`;

  const { data, status, error } = useQuery(["sources", projectId], () =>
    callApi(`/api/sources?project=${projectId}`, "GET", instance, accounts)
  );

  if (status === "error") {
    return <span>Error: {error.message}</span>;
  }

  if (status === "loading") {
    return <span>Loading</span>;
  }
  return (
    <div>
      <Breadcrumbs urlMatch={urlMatch} />
      <Row>
        <Col>
          <h2 className="categoryheader">Project Sources</h2>
        </Col>
        <Col md="auto">
          <Button
            className="create-new"
            variant="outline-primary"
            onClick={() => setSourceForm(!sourceForm)}
          >
            {sourceForm ? "Cancel" : "New Source"}
          </Button>
        </Col>
      </Row>
      <Collapse in={sourceForm}>
        <div className="form">
          <SourceForm
            parent={projectApiURL}
            projectId={projectId}
            method="POST"
            URL={`/api/sources/`}
            accounts={accounts}
            instance={instance}
            queryKey="sources"
            setForm={setSourceForm}
            global={false}
          />
        </div>
      </Collapse>
      <Row>
        <SourceTable
          data={data}
          endpoint={"sources"}
          edit={true}
          global={false}
        />
      </Row>
    </div>
  );
};

export default Sources;
