import * as yup from "yup";

export const electricalNetworkSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  //ref: yup.number().required("A Ref No. is required"),
  diversity: yup
    .number()
    .required("Diversity is required")
    .max(1, "Diversity must be between 0 and 1"),
});
