import { useMutation, useQueryClient, QueryKey } from "react-query";
import { useMsal } from "@azure/msal-react";
import { callPost } from "../auth/callApi";

export const useCreate = <F extends object, T>(
  model: string,
  key: QueryKey,
  callBack?: () => void
) => {
  const queryClient = useQueryClient();
  const { instance } = useMsal();
  const mutation = useMutation<T, unknown, F>(
    (data) =>
      callPost(`${process.env.REACT_APP_URL}/api/${model}/`, data, instance),
    {
      mutationKey: key,
      onSuccess: () => {
        queryClient.invalidateQueries(key);
        callBack && callBack();
      },
    }
  );

  return mutation;
};
