import React, { useState } from "react";
import { useQuery } from "react-query";
import { useMsal } from "@azure/msal-react";
import callApi from "../helpers/callApi";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import SpaceTypeTable from "../components/SpaceTypeTable";
import Button from "react-bootstrap/Button";
import SpaceTypeForm from "../components/SpaceTypeForm";
import Collapse from "react-bootstrap/Collapse";

const GlobalSpaceTypes = () => {
  const { instance, accounts } = useMsal();

  const [spaceTypeForm, setSpaceTypeForm] = useState(false);

  const { data, status, error } = useQuery("global_space_types", () =>
    callApi(`/api/global_space_types/`, "GET", instance, accounts)
  );

  if (status === "error") {
    return <span>Error: {error.message}</span>;
  }

  if (status === "loading") {
    return <span>Loading</span>;
  }
  console.log(accounts);
  return (
    <div>
      <Row>
        <Col>
          <h2 className="categoryheader">Global Space Types</h2>
        </Col>
        <Col md="auto">
          {accounts[0].idTokenClaims.roles.includes("Admin.Write") ? (
            <Button
              className="create-new"
              variant="outline-primary"
              onClick={() => setSpaceTypeForm(!spaceTypeForm)}
              disabled={
                !accounts[0].idTokenClaims.roles.includes("Admin.Write")
              }
            >
              {spaceTypeForm ? "Cancel" : "New Global Space Type"}
            </Button>
          ) : (
            <></>
          )}
        </Col>
      </Row>
      <Collapse in={spaceTypeForm}>
        <div className="form">
          <SpaceTypeForm
            method="POST"
            URL="/api/global_space_types/"
            accounts={accounts}
            instance={instance}
            queryKey="global_space_types"
            setForm={setSpaceTypeForm}
          />
        </div>
      </Collapse>
      <Row>
        <SpaceTypeTable
          data={data}
          edit={
            global && accounts[0].idTokenClaims.roles.includes("Admin.Write")
          }
        />
      </Row>
    </div>
  );
};

export default GlobalSpaceTypes;
