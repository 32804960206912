import React, { useState, useRef } from "react";
import { useRouteMatch } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import EditButton from "../components/EditButton";
import DeleteCheck from "../components/DeleteCheck";
import SiteForm from "../components/SiteForm";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import BuildingForm from "../forms/BuildingForm";
import Breadcrumbs from "../components/Breadcrumbs";
import ResultCharts from "../components/ResultCharts";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import Modal from "react-bootstrap/Modal";
import Collapse from "react-bootstrap/Collapse";
import { useMsal } from "@azure/msal-react";
import callApi from "../helpers/callApi";
import writeToExcel from "../helpers/writeToExcel";
import { useQuery, useMutation } from "react-query";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import RainfallResults from "../components/RainfallResults";
import Loader from "../components/Loader";
import ElectricalBoardForm from "forms/ElectricalBoardForm";
import { useElectricalBoards } from "queries";

const Buildings = (props) => {
  const { instance, accounts } = useMsal();

  const { SearchBar } = Search;

  const urlMatch = useRouteMatch();
  const siteId = urlMatch.params.siteId;
  const [edit, setEdit] = useState(false);
  const [viewElectrical, setViewElectrical] = useState(false);
  const [editElectrical, setEditElectrical] = useState({ open: false });
  const [buildingForm, setBuildingForm] = useState(false);
  const [missingFields, setMissingFields] = useState({ state: false });
  const [selected, setSelected] = useState([]);
  const [noSpace, setNoSpace] = useState(false);
  const [editBuilding, setEditBuilding] = useState({ open: false });

  const results = useRef(null);
  const rainfallResult = useRef(null);

  const handleSelect = (building) => {
    var array = [...selected];
    var index = array.indexOf(building);
    if (index !== -1) {
      array.splice(index, 1);
      setSelected(array);
    } else {
      // Add building to selection
      setSelected(selected.concat(building));
    }
  };

  const buildingToSpaces = () => {
    const spaces_array = selected.map((building) => {
      return building.spaces.map((space) => {
        return space.id;
      });
    });
    console.log(spaces_array);
    const merged = [].concat.apply([], spaces_array);
    console.log(merged);
    return merged;
  };

  const {
    data: site,
    isLoading,
    error,
  } = useQuery(["site", siteId], () =>
    callApi(`/api/sites/${siteId}`, "GET", instance, accounts)
  );

  const { boards } = useElectricalBoards(siteId)

  const calculateMutation = useMutation(
    () =>
      callApi(
        `/api/calculate/`,
        "POST",
        instance,
        accounts,
        { space_ids: buildingToSpaces(selected) },
        "application/json",
        true
      ),
    {
      mutationKey: "calculate",
      onSuccess: () => {
        results.current.scrollIntoView({ behavior: "smooth" });
      },
    }
  );

  const rainfallMutation = useMutation(
    () =>
      callApi(
        `/api/calculate/`,
        "POST",
        instance,
        accounts,
        { building_ids: selected.map((building) => building.id) },
        "application/json",
        true
      ),
    {
      mutationKey: "calculate",
      onSuccess: () => {
        rainfallResult.current.scrollIntoView({ behavior: "smooth" });
      },
    }
  );

  const defaultSorted = [
    {
      dataField: "ref",
      order: "asc",
    },
  ];

  const columns = [
    {
      dataField: "ref",
      text: "Ref",
      sort: true,
      sortFunc: (a, b) => {
        let hasNumber = /\d/;
        if (hasNumber.test(a) || hasNumber.test(b)) {
          return a.match(/\d+/g) - b.match(/\d+/g);
        } else {
          return a - b;
        }
      },
      formatter: (cell, row) =>
        !accounts[0].idTokenClaims.roles.includes("Guest.Read") ? (
          <LinkContainer
            to={{
              pathname: `${urlMatch.url}/building/${row.id}/${row.name}`,
              state: { buildingURL: row.url },
            }}
          >
            <div className="table-link">{row.ref}</div>
          </LinkContainer>
        ) : (
          <div>{row.ref}</div>
        ),
      footer: "",
    },
    {
      dataField: "name",
      text: "Name",
      footer: "",
    },
    {
      dataField: "GIFA",
      text: "GIFA (m²)",
      footer: (columnData) =>
        columnData.reduce((acc, item) => acc + item, 0) + " m²",
    },
    {
      dataField: "roof_area",
      text: "Roof Area (m²)",
    },
    {
      dataField: "build_date",
      text: "Build Date",
      footer: "",
    },
    {
      dataField: "demo_date",
      text: "Demolition Date",
      footer: "",
    },
    {
      dataField: "edit",
      text: "",
      formatter: (cell, row) => (
        <div style={{ marginLeft: "30px" }}>
          <EditButton
            handleEdit={() => {
              setEditBuilding({ open: true, existing: row });
            }}
            size=""
            disabled={accounts[0].idTokenClaims.roles.includes("Guest.Read")}
          />
        </div>
      ),
      isDummyField: true,
      footer: "",
    },
    {
      dataField: "delete",
      text: "",
      formatter: (cell, row) => (
        <DeleteCheck
          id={row.id}
          queryKey={["site", siteId]}
          type="buildings"
          context="building"
          disabled={accounts[0].idTokenClaims.roles.includes("Guest.Read")}
        />
      ),
      isDummyField: true,
      footer: "",
    },
  ];

  const electricalColumns = [
    {
      dataField: "name",
      text: "Name",
      footer: "",
      sort: true
    },
    {
      dataField: "ref",
      text: "Ref",
      footer: "",
    },
    {
      dataField: "edit",
      text: "",
      formatter: (cell, row) => (
        <div style={{ marginLeft: "30px" }}>
          <EditButton
            handleEdit={() => {
              setEditElectrical({ open: true, existing: row });
            }}
            disabled={accounts[0].idTokenClaims.roles.includes("Guest.Read")}
          />
        </div>
      ),
      isDummyField: true,
      footer: "",
    },
    {
      dataField: "delete",
      text: "",
      formatter: (cell, row) => (
        <DeleteCheck
          id={row.id}
          queryKey={["electrical_boards", siteId]}
          type="electrical_boards"
          disabled={accounts[0].idTokenClaims.roles.includes("Guest.Read")}
          context="electrical board"
        />
      ),
      isDummyField: true,
      footer: "",
    },
  ];

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    hideSelectAll: true,
    hideSelectColumn: accounts[0].idTokenClaims.roles.includes("Guest.Read"),
    onSelect: (row) => {
      handleSelect(row);
    },
  };

  const handleTabSelect = (key) => {
    if (key === "electrical") {
      setViewElectrical(true);
      setBuildingForm(false);
    } else {
      setViewElectrical(false);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <span>Error: {error.message}</span>;
  }

  return (
    <div>
      <Breadcrumbs urlMatch={urlMatch} />
      {/** Edit Header*/}
      <Row>
        <Col md="auto">
          <h2 className={edit ? "categoryheadergreyed" : "categoryheader"}>
            Site: {site.name}
          </h2>
        </Col>
        {!accounts[0].idTokenClaims.roles.includes("Guest.Read") && (
          <>
            <Col>
              <EditButton handleEdit={() => setEdit(!edit)} />
            </Col>

            <Col md="auto">
              <LinkContainer to={`${urlMatch.url}/electrical_network/`}>
                <Button variant="outline-primary">Electrical Diagrams</Button>
              </LinkContainer>
            </Col>
            <Col md="auto">
              {site.geojson ? (
                <LinkContainer to={`${urlMatch.url}/site_map/`}>
                  <Button variant="outline-primary">Site Map</Button>
                </LinkContainer>
              ) : (
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-disabled">
                      Add a GeoJSON to this site to view
                    </Tooltip>
                  }
                  placement="left"
                  delay={{ show: 250, hide: 400 }}
                >
                  <span className="d-inline-block">
                    <Button
                      variant="outline-primary"
                      disabled
                      style={{ pointerEvents: "none" }}
                    >
                      Site Map
                    </Button>
                  </span>
                </OverlayTrigger>
              )}
            </Col>

            <Col md="auto">
              <Button
                className="export"
                variant="outline-primary"
                onClick={() => writeToExcel(site, setMissingFields)}
              >
                Export To Excel
              </Button>
            </Col>
          </>
        )}
      </Row>
      <Collapse in={edit}>
        <div>
          <SiteForm
            method="PATCH"
            URL={`/api/sites/${siteId}/`}
            initialValues={{
              name: site.name,
              ref: site.ref,
              weather: {
                value: site.weather.url,
                label: site.weather.name,
              },
              rain_intensity: site.rain_intensity,
              description: site.description,
              geojson: JSON.stringify(site.geojson, null, "\t"),
            }}
            setForm={setEdit}
            queryKey={["site", siteId]}
            instance={instance}
            accounts={accounts}
          />
        </div>
      </Collapse>

      <p>
        {site.ref} | {site && site.weather.name}
      </p>
      <Row>
        <Col>
          <h3>
            {viewElectrical ? "Electrical Board List" : "Building List"}
          </h3>
        </Col>
        {!accounts[0].idTokenClaims.roles.includes("Guest.Read") &&
          !viewElectrical && (
            <Col md="auto">
              <Button
                className="create-new"
                variant="outline-primary"
                onClick={() => setBuildingForm(!buildingForm)}
              >
                {buildingForm ? "Cancel" : "New Building"}
              </Button>
            </Col>
          )}

      </Row>

      <Collapse in={buildingForm}>
        <div className="form">
          <BuildingForm
            method="create"
            queryKey={["site", siteId]}
            setClose={setBuildingForm}
            geoData={site.geojson}
            siteId={siteId}
          />
        </div>
      </Collapse>
      <Tabs
        defaultActiveKey="buildings"
        transition={false}
        id="noanim-tab-example"
        className="mb-3"
        onSelect={handleTabSelect}
      >
        {site.buildings && (
          <Tab eventKey="buildings" title="Buildings">
            <ToolkitProvider
              keyField="id"
              data={site.buildings}
              columns={columns}
              search
            >
              {(props) => (
                <div>
                  <SearchBar
                    {...props.searchProps}
                    style={{ width: "300px" }}
                  />
                  <BootstrapTable
                    {...props.baseProps}
                    bordered={false}
                    hover
                    selectRow={selectRow}
                    defaultSorted={defaultSorted}
                    noDataIndication="No buildings have been created yet, click 'New Building' to make one"
                  />
                </div>
              )}
            </ToolkitProvider>
          </Tab>
        )}
        {boards &&  (
          <Tab eventKey="electrical" title="Electrical Boards">
            <ToolkitProvider
              keyField="id"
              data={boards}
              columns={electricalColumns}
              search
            >
              {(props) => (
                <div>
                  <SearchBar
                    {...props.searchProps}
                    style={{ width: "300px" }}
                  />
                  <BootstrapTable
                    {...props.baseProps}
                    bordered={false}
                    hover
                    defaultSorted={defaultSorted}
                    noDataIndication="No electrical boards been created yet, click 'New Electrical Board' to make one"
                  />
                </div>
              )}
            </ToolkitProvider>
          </Tab>
        )}
      </Tabs>
      {!accounts[0].idTokenClaims.roles.includes("Guest.Read") &&
        !viewElectrical && (
          <Button
            style={{ marginRight: "15px" }}
            onClick={() => {
              if (selected.length === 0) {
                setNoSpace(true);
              } else {
                calculateMutation.mutate();
              }
            }}
          >
            {calculateMutation.isSuccess ? "Recalculate" : "Calculate"}
          </Button>
        )}

      {!accounts[0].idTokenClaims.roles.includes("Guest.Read") &&
        !viewElectrical && (
          <Button
            onClick={() => {
              if (selected.length === 0) {
                setNoSpace(true);
              } else {
                rainfallMutation.mutate();
              }
            }}
          >
            Calculate Rainfall
          </Button>
        )}

      <div ref={results}>
        <ResultCharts calculateMutation={calculateMutation} />
      </div>

      <div ref={rainfallResult}>
        <RainfallResults calculateMutation={rainfallMutation} />
      </div>

      <Modal centered show={noSpace} onHide={() => setNoSpace(false)}>
        <Modal.Header closeButton>
          <Modal.Title>No Buildings selected</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Please select a building to perform a calculation.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setNoSpace(false)}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        centered
        show={missingFields.state}
        onHide={() => setMissingFields({ state: false })}
      >
        <Modal.Header closeButton>
          <Modal.Title>Missing Fields</Modal.Title>
        </Modal.Header>
        <Modal.Body>You're summary has been exported...</Modal.Body>
        <Modal.Body></Modal.Body>
        <Modal.Body>
          WARNING: the following buildings contain at least one empty field,
          this may cause calculations to be inaccurate
        </Modal.Body>
        <Modal.Body>
          {missingFields.missingKeys &&
            missingFields.missingKeys.map((key) => "-" + key.building)}
        </Modal.Body>
        <Modal.Body></Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => setMissingFields({ state: false })}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        centered
        show={editBuilding.open}
        dialogClassName="modal-100w"
        onHide={() => setEditBuilding({ ...editBuilding, open: false })}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Building</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editBuilding.existing && (
            <BuildingForm
              modal={true}
              method="update"
              updateId={editBuilding.existing.id}
              queryKey={["site", siteId]}
              setClose={() => setEditBuilding({ open: false })}
              geoData={site.geojson}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setEditBuilding({ ...editBuilding, open: false })}
          >
            Cancel
          </Button>
          <Button variant="primary" type="submit" form="building-edit-form">
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        centered
        show={editElectrical.open}
        dialogClassName="modal-100w"
        onHide={() =>
          setEditElectrical({ ...editElectrical, open: false })
        }
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Electrical Board</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editElectrical.existing && (
            <ElectricalBoardForm
              method="update"
              updateId={editElectrical.existing.id}
              queryKey={["electrical_boards", siteId]}
              setClose={() => setEditElectrical({ open: false })}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() =>
              setEditElectrical({ ...editElectrical, open: false })
            }
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            form="electrical-edit-form"
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Buildings;
