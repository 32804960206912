import callApi from "./callApi";

const getFuelTypes = async (
  instance,
  accounts,
  url = "/api/global_fuel_types"
) => {
  const res = await callApi(url, "GET", instance, accounts);
  const options = res.map((st) => ({
    value: st.url,
    label: st.name,
  }));
  return options;
};

export default getFuelTypes;
