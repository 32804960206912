import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import { QueryKey } from "react-query";
import Datetime from "react-datetime";
import moment from "moment";
import "react-datetime/css/react-datetime.css";
import MapSelection from "../components/MapSelection";
import { Building, BuildingFeatureCollection, BuildingFields } from "types";
import { useRouteMatch } from "react-router-dom";
import { useBuildings, useSite } from "queries";
import { useCreate, useUpdate } from "mutations";
import { buildingSchema } from "./schemas/buildingSchema";
type Props = {
  method: "create" | "update";
  parent?: number;
  setClose: () => void;
  updateId?: number;
  geoData: BuildingFeatureCollection;
  queryKey: QueryKey;
  siteMap?: boolean;
  modal?: boolean;
};

//parts removes some of the fields for the edit function
//submit changes the submit button text depending on context
function BuildingForm({
  method,
  setClose,
  updateId,
  geoData,
  queryKey,
  siteMap = false,
  modal = false,
}: Props) {
  const urlMatch: any = useRouteMatch();
  const siteId = urlMatch.params.siteId;
  const { buildings } = useBuildings(siteId);
  const [buildingGeoId, setBuildingGeoId] = useState<number | undefined>(
    undefined
  );
  const { site } = useSite(siteId);
  const [initialValues, setInitialValues] = useState<BuildingFields>({
    name: "",
    ref: "",
    build_date: moment().format("DD/MM/YYYY"),
    demo_date: moment().format("DD/MM/YYYY"),
    roof_area: 0,
    site: site?.url || "",
    description: "",
    geo_id: 0,
  });
  const createMutation = useCreate<BuildingFields, Building>(
    "buildings",
    queryKey,
    () => setClose()
  );
  const editMutation = useUpdate<BuildingFields, Building>(
    "buildings",
    queryKey,
    () => setClose()
  );

  useEffect(() => {
    if (method === "update" && buildings) {
      let building = buildings.find((building) => building.id === updateId);
      console.log(buildings);
      building && setInitialValues(building);
      setBuildingGeoId(building?.geo_id);
      console.log(initialValues);
    }
  }, [buildings, method, updateId]);

  return (
    <Formik
      validationSchema={buildingSchema}
      onSubmit={async (
        values,
        { setSubmitting, setErrors, setStatus, resetForm }
      ) => {
        buildingGeoId && (values.geo_id = buildingGeoId);
        values.build_date = moment(values.build_date).format("YYYY-MM-DD");
        values.demo_date = moment(values.demo_date).format("YYYY-MM-DD");
        try {
          if (method === "create") {
            createMutation.mutate(values);
          } else {
            updateId &&
              editMutation.mutate({ data: values, id: updateId.toString() });
          }
          resetForm({});
          setStatus({ success: true });
        } catch (error) {
          console.log(error);
          setStatus({ success: false });
          setSubmitting(false);
        }
      }}
      enableReinitialize
      initialValues={initialValues}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
        values,
        touched,
        errors,
      }) => (
        <Form
          className="projectform"
          id={method === "update" ? "building-edit-form" : "building-form"}
          onSubmit={handleSubmit}
        >
          <Card style={{ padding: "20px 18px 0px 18px", margin: "15px 0px" }}>
            <Form.Row>
              <Col md="4">
                <Form.Group controlId="validationFormik01">
                  <Form.Label>Building Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.name && !!errors.name}
                    className="mb-2 mr-sm-2"
                    autoComplete="off"
                  />
                  <Form.Control.Feedback type="invalid">
                    {touched.name && errors.name}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="validationFormik01">
                  <Form.Label>Build Date</Form.Label>
                  <Datetime
                    initialValue={initialValues.build_date}
                    value={moment(values.build_date).format("DD/MM/YYYY")}
                    dateFormat="DD/MM/YYYY"
                    timeFormat={false}
                    onChange={(e) => {
                      setFieldValue("build_date", e);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {touched.build_date && errors.build_date}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="validationFormik01">
                  <Form.Label>Demolition Date</Form.Label>
                  <Datetime
                    initialValue={initialValues.demo_date}
                    value={moment(values.demo_date).format("DD/MM/YYYY")}
                    dateFormat="DD/MM/YYYY"
                    timeFormat={false}
                    onChange={(e) => {
                      setFieldValue("demo_date", e);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {touched.demo_date && errors.demo_date}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="validationFormik10">
                  <Form.Label>Roof Area</Form.Label>
                  <InputGroup className="mb-2">
                    <Form.Control
                      type="number"
                      name="roof_area"
                      autoComplete="off"
                      value={values.roof_area}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="mb-2 intfields"
                      isInvalid={touched.roof_area && !!errors.roof_area}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text className="mb-2">
                        m<sup>2</sup>
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    {touched.roof_area && errors.roof_area}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={siteMap ? "8" : "4"}>
                {method === "create" && (
                  <Form.Group controlId="validationFormik02">
                    <Form.Label>Building Ref</Form.Label>
                    <Form.Control
                      type="text"
                      name="ref"
                      value={values.ref}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.ref && !!errors.ref}
                      className="mb-2 mr-sm-2"
                      autoComplete="off"
                    />
                    <Form.Control.Feedback type="invalid">
                      {touched.ref && errors.ref}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                )}
                <Form.Group controlId="validationFormik02">
                  <Form.Label>Comments</Form.Label>
                  <Form.Control
                    as="textarea"
                    type="text"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.description && !!errors.description}
                    className="mb-2 mr-sm-2"
                    style={{
                      height: method === "create" ? "125px" : "210px",
                      width: siteMap ? "290px" : "",
                    }}
                    autoComplete="off"
                  />
                  <Form.Control.Feedback type="invalid">
                    {touched.description && errors.description}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
              </Col>
              {!siteMap && (
                <Col>
                  <div style={{ marginBottom: "25px" }}>
                    <div style={{ marginBottom: "8px" }}>
                      Building Selection
                    </div>
                    {buildingGeoId && (
                      <MapSelection
                        geoData={geoData}
                        setBuildingGeoId={setBuildingGeoId}
                        buildingGeoId={buildingGeoId}
                      />
                    )}
                    <p style={{ fontSize: "12px" }}>
                      <b>{buildingGeoId && "GeoJSON ID: " + buildingGeoId}</b>
                    </p>
                  </div>
                </Col>
              )}
            </Form.Row>
            {!modal && (
              <Form.Group>
                <Button type="submit" className="mb-2">
                  {method === "create" ? "Create" : "Save"}
                </Button>
              </Form.Group>
            )}
          </Card>
        </Form>
      )}
    </Formik>
  );
}

export default BuildingForm;
