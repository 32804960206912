import { Grid } from "react-loader-spinner";
const Loader = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        alignItems: "center",
        height: "20vh",
        flexWrap: "wrap",
      }}
    >
      <Grid color="#69be94" height={80} width={80} />
      <h2 style={{ width: "100%", textAlign: "center", color: "#aeb0b3" }}>
        Loading...
      </h2>
    </div>
  );
};

export default Loader;
