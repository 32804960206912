import { useQuery } from "react-query";
import { Site, SiteGeo } from "types";
import { useMsal } from "@azure/msal-react";
import { callGet } from "../auth/callApi";

export const useSites = (projectId?: string) => {
  const { instance } = useMsal();
  const { data, isLoading, error } = useQuery<Site[]>(
    ["sites", projectId],
    () =>
      callGet(
        `${process.env.REACT_APP_URL}/api/sites?project=${projectId}`,
        instance
      ),
    {
      enabled: !!projectId,
    }
  );
  return {
    sites: data,
    isLoading,
    error,
  };
};

export const useSite = (siteID?: string) => {
  const { instance } = useMsal();
  const { data, isLoading, error } = useQuery<Site>(
    ["site", siteID],
    () => callGet(`${process.env.REACT_APP_URL}/api/sites/${siteID}`, instance),
    {
      enabled: !!siteID,
    }
  );
  return {
    site: data,
    isLoading,
    error,
  };
};

export const useSiteGeo = (siteID?: string) => {
  const { instance } = useMsal();
  const { data, isLoading, error } = useQuery<SiteGeo>(
    ["siteGeo", siteID],
    () =>
      callGet(
        `${process.env.REACT_APP_URL}/api/sites/${siteID}/geojson`,
        instance
      ),
    {
      enabled: !!siteID,
    }
  );
  return {
    siteGeo: data,
    isLoading,
    error,
  };
};
