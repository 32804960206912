import * as yup from "yup";

export const buildingSchema = yup.object().shape({
  name: yup.string().required(),
  ref: yup.string().required(),
  build_date: yup.date().required(),
  demo_date: yup
    .date()
    .when(
      "build_date",
      (build_date, schema) => build_date && schema.min(build_date)
    ),
  roof_area: yup.number().nullable(),
  external_loads: yup.object().nullable(),
  description: yup.string().nullable(),
});
