import React from "react";
import { useState } from "react";
import { useRouteMatch, Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import EditButton from "../components/EditButton";
import DeleteCheck from "../components/DeleteCheck";
import SiteForm from "../components/SiteForm";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ProjectForm from "../components/ProjectForm";
import Breadcrumbs from "../components/Breadcrumbs";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import Collapse from "react-bootstrap/Collapse";
import { useMsal } from "@azure/msal-react";
import callApi from "../helpers/callApi";
import { useQuery } from "react-query";
import Modal from "react-bootstrap/Modal";
import Loader from "../components/Loader";

const Sites = () => {
  const { instance, accounts } = useMsal();

  const { SearchBar } = Search;
  const urlMatch = useRouteMatch();
  const projectId = urlMatch.params.projectId;
  const projectURL = `/project/${urlMatch.params.projectId}/${urlMatch.params.projectName}`;
  const [edit, setEdit] = useState(false);
  const [editSite, setEditSite] = useState({ open: false });
  const [siteForm, setSiteForm] = useState(false);

  const {
    data: project,
    isLoading: loadingProject,
    error,
  } = useQuery(["project", projectId], () =>
    callApi(`/api/projects/${projectId}`, "GET", instance, accounts)
  );

  const { data: sites, isLoading: loadingSites } = useQuery(
    ["sites", projectId],
    () => callApi(`/api/sites/?project=${projectId}`, "GET", instance, accounts)
  );

  // Columns for list of sites table
  const columns = [
    {
      dataField: "name",
      text: "Name",
      formatter: (cell, row) => (
        <LinkContainer
          to={{
            pathname: `${urlMatch.url}/site/${row.id}/${row.name}`,
            state: { siteURL: row.url },
          }}
        >
          <div className="table-link">{row.name}</div>
        </LinkContainer>
      ),
    },
    {
      dataField: "ref",
      text: "Ref",
    },
    {
      dataField: "weather",
      text: "Weather File",
      formatter: (cell, row) => {
        return row.weather.name;
      },
    },
    {
      dataField: "rain_intensity",
      text: "Rainfall Intensity (mm/hr)",
    },
    {
      dataField: "edit",
      text: "",
      formatter: (cell, row) => (
        <div style={{ marginLeft: "30px" }}>
          <EditButton
            handleEdit={() => {
              setEditSite({ open: true, existing: row });
            }}
            disabled={accounts[0].idTokenClaims.roles.includes("Guest.Read")}
          />
        </div>
      ),
      isDummyField: true,
    },
    {
      dataField: "delete",
      text: "",
      formatter: (cell, row) => (
        <DeleteCheck
          id={row.id}
          queryKey={["sites", projectId]}
          type="sites"
          disabled={accounts[0].username !== project.owner}
          context="site"
        />
      ),
      isDummyField: true,
    },
  ];

  if (loadingSites || loadingProject) {
    return <Loader />;
  }

  if (error) {
    return <span>Error: {error.message}</span>;
  }
  return (
    <div>
      <Breadcrumbs urlMatch={urlMatch} />
      <Row>
        <Col md="auto">
          {edit ? (
            <h2 className="categoryheadergreyed">Project: {project.name}</h2>
          ) : (
            <h2 className="categoryheader">Project: {project.name}</h2>
          )}
        </Col>
        {!accounts[0].idTokenClaims.roles.includes("Guest.Read") && (
          <>
            <Col>
              <EditButton handleEdit={() => setEdit(!edit)} />
            </Col>
            <Col md="auto">
              <Link
                to={`${projectURL}/fuel_types`}
                className="btn btn-outline-primary"
              >
                Fuel Types
              </Link>
            </Col>
            <Col md="auto">
              <Link
                to={`${projectURL}/sources`}
                className="btn btn-outline-primary"
              >
                Sources
              </Link>
            </Col>
            <Col md="auto">
              <Link
                to={`${projectURL}/demand`}
                className="btn btn-outline-primary"
              >
                Distribution
              </Link>
            </Col>
            <Col md="auto">
              <Link
                to={`${projectURL}/space_types`}
                className="btn btn-outline-primary"
              >
                Space Types
              </Link>
            </Col>
          </>
        )}
      </Row>
      <Collapse in={edit}>
        <div>
          <ProjectForm
            method="PATCH"
            URL={`/api/projects/${project.id}/`}
            queryKey={["project", projectId]}
            initialValues={{
              name: project.name,
              ref: project.ref,
              security_group: {
                value: project.security_group,
                label: project.security_group,
              },
            }}
            accounts={accounts}
            instance={instance}
            setForm={setEdit}
          />
        </div>
      </Collapse>
      <p>{project.ref}</p>

      <Row>
        <Col>
          <h3>Site List</h3>
        </Col>
        {!accounts[0].idTokenClaims.roles.includes("Guest.Read") && (
          <Col md="auto">
            <Button
              className="create-new"
              variant="outline-primary"
              onClick={() => setSiteForm(!siteForm)}
            >
              {siteForm ? "Cancel" : "New Site"}
            </Button>
          </Col>
        )}
      </Row>
      <Collapse in={siteForm}>
        <div className="form">
          <SiteForm
            method="POST"
            URL={"/api/sites/"}
            submit={"Create"}
            initialValues={{
              name: "",
              ref: "",
              weather: "",
              rain_intensity: 0,
              geojson: "",
            }}
            parent={project.url}
            instance={instance}
            accounts={accounts}
            queryKey={["sites", projectId]}
            setForm={(state) => setSiteForm(state)}
          />
        </div>
      </Collapse>

      {sites ? (
        <ToolkitProvider keyField="ref" data={sites} columns={columns} search>
          {(props) => (
            <div>
              <SearchBar {...props.searchProps} style={{ width: "300px" }} />
              <br />
              <BootstrapTable
                {...props.baseProps}
                bordered={false}
                hover
                noDataIndication="No sites have been created yet, click 'New Site' to make one"
              />
            </div>
          )}
        </ToolkitProvider>
      ) : null}
      <Modal
        centered
        show={editSite.open}
        dialogClassName="modal-100w"
        onHide={() => setEditSite({ ...editSite, open: false })}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Site</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editSite.existing && (
            <SiteForm
              method="PATCH"
              modal={true}
              URL={`/api/sites/${editSite.existing.id}/`}
              accounts={accounts}
              instance={instance}
              initialValues={{
                name: editSite.existing.name,
                ref: editSite.existing.ref,
                weather: {
                  value: editSite.existing.weather.url,
                  label: editSite.existing.weather.name,
                },
                rain_intensity: editSite.existing.rain_intensity,
                geojson: JSON.stringify(editSite.existing.geojson, null, "\t"),
              }}
              queryKey={["project", projectId]}
              setForm={(state) => setEditSite({ open: state })}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setEditSite({ ...editSite, open: false })}
          >
            Cancel
          </Button>
          <Button variant="primary" type="submit" form="site-edit-form">
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Sites;
