import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { LinkContainer } from "react-router-bootstrap";
import { useHistory } from "react-router";
import Arup from "../assets/Arup";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { Login } from "@microsoft/mgt-react";

const Header = () => {
  const { accounts } = useMsal();
  let history = useHistory();

  return (
    <div className="header">
      <AuthenticatedTemplate>
        <Navbar className="container-fluid" bg="dark" variant="dark">
          <LinkContainer to="/">
            <Navbar.Brand>SEC</Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <LinkContainer to="/">
                <Nav.Link>Home</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/projects">
                <Nav.Link>Projects</Nav.Link>
              </LinkContainer>
              {!accounts[0].idTokenClaims.roles.includes("Guest.Read") && (
                <NavDropdown
                  id="nav-dropdown-global-templates"
                  title="Global Templates"
                >
                  <NavDropdown.Item
                    onClick={() => history.push("/global_fuel_types")}
                  >
                    Fuels
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => history.push("/global_sources")}
                  >
                    Sources
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => history.push("/global_demand")}
                  >
                    Distribution
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => history.push("/global_space_types")}
                  >
                    Space Types
                  </NavDropdown.Item>
                </NavDropdown>
              )}
            </Nav>
          </Navbar.Collapse>
          <Login slot="user" className="ml-auto" />
          <a href="https://arup.com">
            <Arup className="ml-auto" />
          </a>
        </Navbar>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Navbar className="container-fluid" bg="dark" variant="dark">
          <LinkContainer to="/">
            <Navbar.Brand>SEC</Navbar.Brand>
          </LinkContainer>
          <a href="https://arup.com">
            <Arup className="ml-auto" />
          </a>
        </Navbar>
      </UnauthenticatedTemplate>
    </div>
  );
};

export default Header;
