import XLSX from "xlsx";

const numsToDays = (numStr) => {
	const dayList = [
		"Sunday",
		"Monday",
		"Tuesday",
		"Wednesday",
		"Thursday",
		"Friday",
		"Saturday",
	];
	let numArr = numStr.split(",");
	let running_days_arr = [];
	numArr.forEach((num) => {
		running_days_arr.push(dayList[parseInt(num)]);
	});
	let running_days = running_days_arr.join(",");
	return running_days;
};

const writeToExcel = (site, setMissingFields) => {
	let wb = XLSX.utils.book_new("Testing");
	/* make worksheet */
	let ws_headers = [
		"Space Ref",
		"Area (M^2)",
		"Space Type",
		"Heating System",
		"Cooling System",
		"Humidification System",
		"Air Temperature (C)",
		"Relative Humidity (%)",
		"Air Changes (/hr)",
		"Height (M)",
		"Fresh Air (%)",
		"Ventilation Safety Factor",
		"HWS Load Density",
		"Process Load Density",
		"Start Time",
		"End Time",
		"Lighting Load (W/M^2)",
		"Lighting Load Power(W)",
		"Small Power Load (W/M^2)",
		"Small Power Load Power (W)",
		"Equipment Load (W/M^2)",
		"Equipment Load Power (W)",
		"Operational Days",
	];
	let summ_data = [
		["Building Name", "Gross Floor Area (M^2)", "Total Electrical Load (W)"],
	];
	let buildings_data = [];
	let ws_names = [];
	let total_area = 0;
	let total_power = 0;
	let errors = [];
	site.buildings.forEach((building) => {
		building.spaces.forEach((space) => {
			let keys = Object.keys(space);
			const missingKeys = keys.filter((key) => !space[key]);
			missingKeys &&
				errors.push({
					building: building.name,
					space: space.ref,
					keys: missingKeys,
				});
		});
		ws_names.push(building.name);
		building.spaces.forEach((space) => {
			total_area += space.area ?? 0;
			total_power +=
				(space.lighting_load ??
					0 + space.small_power_load ??
					0 + space.equipment_load ??
					0) * space.area ?? 0;
			buildings_data.push([
				space?.ref,
				space?.area,
				space?.space_type.name,
				space.heat_source?.name,
				space.cool_source?.name,
				space.humid_source?.name,
				space?.room_air_temp,
				space?.room_air_rh,
				space?.ACperhr,
				space?.height,
				space?.percent_fresh_air,
				space?.ventilation_safety_factor,
				space?.hws_load,
				space?.equipment_load,
				space?.start_time,
				space?.end_time,
				space?.lighting_load,
				(space.lighting_load && space.area) ?? space.lighting_load * space.area,
				space?.small_power_load,
				(space.small_power_load && space.area) ??
					space.small_power_load * space.area,
				space?.equipment_load,
				(space.equipment_load && space.area) ??
					space.equipment_load * space.area,
				numsToDays(space.days),
				null,
			]);
		});
		buildings_data.push([
			"Total Area (M^2):",
			total_area,
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			"Total Electrical Load (W):",
			total_power,
		]);
		summ_data.push([building.name, total_area, total_power]);
		total_area = 0;
		total_power = 0;
	});
	errors.length > 0 && setMissingFields({ state: true, missingKeys: errors });
	let ws = XLSX.utils.aoa_to_sheet(summ_data);
	XLSX.utils.book_append_sheet(wb, ws, "Site Summary");
	let ws_data = [ws_headers];
	let sheet_val = 0;
	buildings_data.forEach((arr) => {
		if (arr.length !== 22) {
			ws_data.push(arr);
		} else {
			ws_data.push(arr);
			ws = XLSX.utils.aoa_to_sheet(ws_data);
			XLSX.utils.book_append_sheet(wb, ws, ws_names[sheet_val]);
			ws_data = [ws_headers];
			sheet_val += 1;
		}
	});

	/* Add the worksheet to the workbook */
	XLSX.writeFile(wb, "out.xlsb");
};

export default writeToExcel;
